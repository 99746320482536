export const ArcPercentage: React.FC<{percentage: number, value?: number, radius: number, thikness: number, color?: string, colorValue?: string}> = ({percentage, value, radius = 50, thikness = 10, color, colorValue})  => {
    const perc: number = (percentage < 0 ? 0 : percentage > 100 ? 100 : percentage) / 100;
    const normalizedRadius = radius - thikness * 2;
    let circumref = (normalizedRadius * 2 * Math.PI) - 0.5 * Math.PI;

    const maxThickness: number = radius * 0.2;
    const mplierThickness: number = thikness < 0 ? 0 : thikness > 1 ? 1 : thikness;
    const stroke: number = mplierThickness * maxThickness;
    
    const strokeDashoffset = circumref - (perc * circumref);
    const fontSize = radius*0.9;
    const textTop = fontSize * 0.3;
    const text: string = (value && (value < 0 ? '' : value > 99 ? '' : value?.toFixed(0)))||'';
    const arcColor = color || 'black';
    const textColor = colorValue || arcColor;

    return (
        <svg height={radius * 2} width={radius * 2}>
            <circle stroke={arcColor} fill='transparent' strokeWidth={stroke} strokeDasharray={circumref + ' ' + circumref} style={{strokeDashoffset}} r={normalizedRadius} cx={radius} cy={radius} transform={`rotate(270 ${radius} ${radius})`} />
            {text && <text stroke='transparent' fill={textColor} textAnchor='middle' x={radius} y={radius + textTop} fontSize={fontSize} >{text}</text>}
        </svg>
    );
}
export default ArcPercentage;