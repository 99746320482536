import { PropsWithChildren } from 'react';
import styles from './styles.module.scss';

interface Props {
  source?: string|null|undefined;
  alt?: string|undefined;
  width?: string|number;
  height?: string|number;
}

const StandardImage: React.FC<PropsWithChildren<Props>> = ({source, alt, width, height, children}) => {

  return (
    <div className={styles.image}
      style={{
        width: width,
        height: height
        }}>
      {(source&&source.length>0) && (
        <img
        src={source}
        alt={alt}
      />
    )}
    {children}
    </div>
  )
}

export default StandardImage;