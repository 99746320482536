import NesteForkAndKnife from "../assets/icons/NesteForkAndKnife";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ClearIcon from "@mui/icons-material/ClearOutlined";
import AlarmIcon from '@mui/icons-material/ErrorOutline';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import BackspaceIcon from '@mui/icons-material/BackspaceOutlined';
import WavingHandIcon from '@mui/icons-material/WavingHand';


export enum storeIcons {
    None = 'None',
    ForkAndKnife = 'ForkAndKnife',
    NesteForkAndKnife = 'NesteForkAndKnife',
    Clear = 'Clear',
    Alarm = 'Alarm',
    Delete = 'Delete',
    Backspace = 'Backspace',
    WavingHand = 'WavingHand',
}
export const IconStore: React.FC<{icon?: storeIcons, iconName?: string, size?:number}> = ({icon = storeIcons.None, iconName = null, size})  => {
    const resolveIcon = () : storeIcons => {
        if (icon === storeIcons.None && iconName !== ''){
            try {
                return storeIcons[iconName as keyof typeof storeIcons];
            } catch {
                return storeIcons.None;
            }
        }
        return icon;
    }
    switch (resolveIcon()) {
        case storeIcons.None: {
            return <></>
        }
        case storeIcons.ForkAndKnife: {
            return <RestaurantIcon style={{width: size, height: size}}/>
        }
        case storeIcons.NesteForkAndKnife: {
            return <NesteForkAndKnife size={size} />
        }
        case storeIcons.Clear: {
            return <ClearIcon style={{width: size, height: size}}/>
        }
        case storeIcons.Alarm: {
            return <AlarmIcon style={{width: size, height: size}}/>
        }
        case storeIcons.Delete: {
            return <DeleteOutlinedIcon style={{width: size, height: size}}/>
        }
        case storeIcons.Backspace: {
            return <BackspaceIcon style={{width: size, height: size}}/>
        }
        case storeIcons.WavingHand: {
            return <WavingHandIcon style={{width: size, height: size}}/>
        }
    }
}