import {
  Paper as MUIPaper,
  PaperProps,
  useTheme,
} from '@mui/material'
import Config from '../../../../hooks/Config';

export default function Container(props: PaperProps) {
  const theme = useTheme();
  const { screenSize } = Config();
  const radius: number = screenSize.getWidth(1);

  return (
    <MUIPaper sx={{borderRadius: radius, backgroundColor: theme.palette.background.default , borderColor: 'transparent', borderWidth: 1, borderStyle: 'solid'}}
        {...props}
    />
  )
}