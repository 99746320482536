import { FC, useContext } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import SalePath from '../../../../models/SalePath';
import { formatMoney } from '../../../../helpers/formatHelper';
import Product from '../../../../models/product';
import { useTranslation } from "react-i18next";
import Button from '../Button';
import StandardImage from '../StandardImage';
import Config from '../../../../hooks/Config';
import SalePathData from '../../../../models/SalePathData';

interface Props {
  product: Product;
  disableControls?: boolean;
  onModify?: (product: Product) => void;
  onRemove?: (product: Product) => void;
}

const BasketProduct: FC<Props> = ({product, disableControls=false, onModify, onRemove}) => {

  const { screenSize } = Config();
  const {t} = useTranslation();
  const {state} = useContext(KioskContext);

  const isInvalid:boolean = SalePathData.isProductInvalid(state.salePathData, product);
  //console.log('isInvalid', isInvalid, product.productName);
  const titleColor:string = isInvalid ? "error" : "text";

  const hideControls:boolean = disableControls || (onModify===undefined && onRemove===undefined);

  const isReadonly:boolean = product.salePathCode.trim()==='';
  const salePath: SalePath|null = state.salePathData.findBySalePathCode(product.salePathCode);
  if (!salePath)return '';

  const renderChildrenLines = (item: Product, depth: number) : string[] => {
    const lines:string[] = [];
    if (item === null || item.products.length === 0) return lines;
    item.products?.forEach(i => {
      const showLine: boolean = (i.isContent && i.quantity === 0) || (!i.isContent && i.quantity !== 0);
      if (showLine){
        lines.push(`${i.quantity===0?`${t('without')} `:''}${i.productName}${(i.salePrice !== 0 && !i.isContent) ? ` (+${formatMoney(i.salePrice)}€)` : ''}`);
      }
      const child:string[] = renderChildrenLines(i, depth+1);
      child?.forEach(c => lines.push(c));
    });
    return lines;
  }

  const onEditClick = () => {
    if (onModify)onModify(product);
  }
  const onRemoveClick = () => {
    if (onRemove)onRemove(product);
  }

  const buttonGap: number = screenSize.getWidth(10);
  const buttonHeight: number = screenSize.getWidth(10);
  const fonmtSize: number = screenSize.getFontSize('body1');

  return (
    <div className={styles.basketProduct} style={{}}>
      <div className={styles.left}>
        <div className={styles.image}>
          <StandardImage source={salePath.imagePath}/>
        </div>
        <div className={styles.ctrl} style={{gap: buttonGap}}>
        {(!hideControls && !isReadonly) && (
          <>
            <Button buttontype='Secondary' fontSize='buttonSmall' style={{height: buttonHeight}} onClick={onEditClick}>{t('button.edit')}</Button>
            <Button buttontype='Secondary' fontSize='buttonSmall' style={{height: buttonHeight}} onClick={onRemoveClick}>{t('button.remove')}</Button>
          </>
        )}
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.title}>
          <Typography variant="body1" color={titleColor} style={{fontWeight: 700, fontSize: fonmtSize}}>{salePath.name}</Typography>
        </div>
        <div className={styles.content} style={{fontSize: fonmtSize}}>
          {isInvalid && (<Typography variant="body1" color={titleColor} style={{fontWeight: 700, fontSize: fonmtSize}}>{t('unable-to-order')}</Typography>)}
          {renderChildrenLines(product, 1).map((l, i) => (<div key={i}>{l}</div>))}
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.qty}>
          <Typography style={{fontWeight: 700, fontSize: fonmtSize}}>{product.quantity} {t('pcs')}</Typography>
        </div>
        <div className={styles.price}>
          <Typography style={{fontWeight: 700, fontSize: fonmtSize}}>{formatMoney(Product.cumulatedTotalAmount(product))} €</Typography>
        </div>
      </div>
  </div>
  );
};

export default BasketProduct;
