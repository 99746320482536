import { FC } from "react";
import { QRCodeSVG } from 'qrcode.react';

export interface Props {
  url:string;
  size?:number;
}

const QRCode: FC<Props> = ({url, size = 100}) => {

  if (url === '') return <></>;

  return (
    <QRCodeSVG value={url} width={size} height={size} />
  );
};

export default QRCode;
