import { FC, useContext, useEffect, useState, memo, useCallback } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from '../../../context/KioskContext';
import InactivityTimer from '../../../hooks/InactivityTimer';
import Config from '../../../hooks/Config';
import Main from '../../components/Main';
import SalePath from '../../../models/SalePath';
import { getSalePaths } from '../../../helpers/searchApi';
import { KioskActionsEnum, StepsEnum } from '../../../context/KioskContext/types';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import useUpdate from '../../../hooks/useUpdate';
import InactivityCounter from '../../components/Controls/InactivityCounter';
import InactivityDialog from '../../components/Dialogs/InactivityDialog';
import AdvertisementPage from '../../components/Controls/AdvertisementPage';
import ClosedPage from '../../components/Controls/ClosedPage';
import InitializingPage from '../../components/Controls/InitializingPage';


type AppStatus = 'Initializing' | 'Browse' | 'Advert' | 'Closed';

interface Props {
}
interface MemoProps {
  updated: Date;
  status: AppStatus;
  returnedFront: boolean;
}

const Root = styled('div')(({ theme }) => ({
})); 

const AppContent = memo<MemoProps>(({updated, status, returnedFront}) => {
  // Outside of rendering executed by 1 sec. timer
  //console.log('Status', status.toUpperCase());
  switch(status){
    case 'Initializing': {return <InitializingPage/>}
    case 'Advert': {return <AdvertisementPage/>}
    case 'Closed': {return <ClosedPage/>}
    case 'Browse': {return <Main returnedFront={returnedFront}/>}
  }
});

const Basket: FC<Props> = () => {
  const {state, dispatch} = useContext(KioskContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contentUpdated, setContentUpdated] = useState<Date>(new Date());
  const [openingHoursCheck, setOpeningHoursCheck] = useState<number>(-1);
  const { debugMode } = Config();
  const [ refreshing, refresh ] = useState<boolean>(false);
  const {i18n} = useTranslation();
  const {inactivitySeconds, inactivityTimerReset} = InactivityTimer();
  const [inactive, setInactive] = useState<boolean>(false);
  const { updateExists, checkForUpdate } = useUpdate();
  const [showFront, setShowFront] = useState<boolean>(false);
  const [returnedFront, setReturnedFront] = useState<boolean>(false);

  useEffect(()=>{
    if (refreshing) {
      refresh(false);
    }
  },[refreshing]);

  const fetchSalePaths = useCallback(async (): Promise<void> => {
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    const data: SalePath[] = await getSalePaths(i18n.language);
    dispatch({type: 'LOADED', salePaths: data});
    setIsLoading(false);
    setContentUpdated(new Date());
  }, [dispatch, i18n.language])

  // Fetch SalePaths
  useEffect((): void => {
    if (!state.isDataLoaded && !isLoading){
      setIsLoading(true);
      fetchSalePaths();
    }
  }, [fetchSalePaths, isLoading, state.isDataLoaded])  

  useEffect((): void => {
    if (!isLoading) {
      //console.log('checkVisibility', state.salePathData);
      state.salePathData?.checkVisibility();
    }
  }, [isLoading, state.salePathData])  

  const touch = () => {
    inactivityTimerReset();
    if (inactive)setInactive(false);
  }
  useEffect(() => {
    if (state.salePath == null){
      const minutes:number = new Date().getMinutes();
      if (openingHoursCheck !== minutes){
        setOpeningHoursCheck(minutes);
        if (state.salePathData?.checkVisibility()){
          setContentUpdated(new Date());
        }
      }
    }
    if (state.settings){
      if ((state.cart||state.salePath)&&!inactive){
        if (state.settings.timerInactivity > 0 && inactivitySeconds >= state.settings.timerInactivity && state.step < StepsEnum.payment){
          setInactive(true);
        }
      } else {
        if (inactivitySeconds >= 5 && updateExists && state.cart == null && state.salePath == null){
          window.location.reload();
        }
        if (state.salePath==null && !inactive && inactivitySeconds >= 20 && i18n.language !== process.env.REACT_APP_DEFAULT_LANGUAGE){
          i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
          dispatch({type: KioskActionsEnum.RELOAD});
        }
        if (state.settings.timerReload > 0 && inactivitySeconds >= state.settings.timerReload){
          checkForUpdate();
          inactivityTimerReset();
          dispatch({type: KioskActionsEnum.RELOAD});
        }
        if (showFront === false && state.salePath==null && state.settings.timerFront > 0 && inactivitySeconds > state.settings.timerFront){
          setShowFront(true);
        }
        if (showFront === true && (state.cart == null || state.cart.products.length === 0) && inactivitySeconds < state.settings.timerFront ){
          setReturnedFront(true);
          setShowFront(false);
        } else {
          setReturnedFront(false);
        }
      }
    }
  },[state.cart, state.salePath, state.salePathData, dispatch, inactive, inactivitySeconds, inactivityTimerReset, openingHoursCheck, i18n, showFront, checkForUpdate, updateExists, state.settings, state.step]);

  useEffect(() => {
    const disableRightClick = (event: MouseEvent): void => {
      inactivityTimerReset();
      event.preventDefault();
    };
    const handleTouchMove = (event: TouchEvent): void => {
      inactivityTimerReset();
      //event.preventDefault();
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };
    const handleTouchStart = (event: TouchEvent): void => {
      inactivityTimerReset();
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchstart', handleTouchStart, { passive: false });
    document.addEventListener('contextmenu', disableRightClick);
    return (): void => {
      document.removeEventListener('contextmenu', disableRightClick);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchstart', handleTouchStart);
    }
  }, [inactivityTimerReset]);


  useEffect(() => {
    if (state.salePath == null){
      const minutes:number = new Date().getMinutes();
      if (openingHoursCheck !== minutes){
        setOpeningHoursCheck(minutes);
        if (state.salePathData?.rootItems.length>0){
          state.salePathData?.checkVisibility();
        }
        setContentUpdated(new Date());
      }
    }
  },[inactivitySeconds, openingHoursCheck, state.salePath, state.salePathData]);

  const resolveAppState = () => {
    if ( !state.isDataLoaded && state.salePathData.rootItems.length===0) return 'Initializing';
    if ( state.salePathData.isClosed()) return 'Closed';
    if (showFront)return 'Advert';
    return 'Browse';
  }

  // Rendered by 1 sec. interval
  return (
    <Root className={styles.basket}>
      {debugMode && (
        <>
          <InactivityCounter count={inactivitySeconds}/>
          {/* <ScreenSize/> */}
          {/* <ChangeCartId/> */}
          {/* <ThrowExampleException/> */}
        </>
      )}
      <AppContent updated={contentUpdated} status={resolveAppState()} returnedFront={returnedFront}/>
      <InactivityDialog visible={inactive} onClose={()=> {touch();}} />
    </Root>
  );
};

export default Basket;
