import { ErrorInfo, FC, useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../../context/KioskContext';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ComponentDialog from '../ComponentDialog';
import ILocalSettings from '../../../../models/localSettings';
import { localSettings } from '../../../../helpers/localStorage';
import Config from '../../../../hooks/Config';

interface Props {
  visible: boolean;
  onClose?: () => void;
}

const InactivityDialog: FC<Props> = ({visible, onClose}) => {

  const {state, dispatch} = useContext(KioskContext);
  const {t, i18n} = useTranslation();
  const [settings] = useState<ILocalSettings>(localSettings.getSettings());
  const { screenSize } = Config();

  const closing = (code: string) => {
    if (code === 'Reset'){
      dispatch({type: KioskActionsEnum.REMOVE_ORDER});
      dispatch({type: KioskActionsEnum.SELECT_ROOT});
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.collect});
      i18n.changeLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);
    }
    if (onClose)onClose();
  }

const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
  handleApplicationError(area, state.cart?.id, undefined, error);
}

return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('InactivityDialog', error, info)}>
    <ComponentDialog visible={visible} onClose={(code:string)=> {closing(code);}}
      actions={[
        { code: 'Reset', caption: t('button.return-to-start'), buttonType: 'Secondary', timer: settings.timerButton},
        { code: 'Continue', caption: t('button.continue-order'), buttonType: 'Primary'},
      ]}
      title={t('confirm-continue')}
      iconName={'WavingHand'}
      fullscreen={false}>
      <Typography variant='body1' fontSize={screenSize.getFontSize('body1')}>{t('inactivity-info')}</Typography>
    </ComponentDialog>
  </ErrorBoundary>
  );
};

export default InactivityDialog;
