import { formatMoney } from "../helpers/formatHelper";
import IDCodeName from "./IDCodeName";
import SalePathOpeningHours from "./SalePathOpeningHours";

export default class SalePath extends IDCodeName {

  nodeType: string;
  infoText: string;
  description: string;
  parentID: number | null;
  parent: SalePath | null = null;
  productID: number | null;
  productCode: string;
  orderNumber: number;
  isOptional: boolean;
  imagePath: string;
  children: SalePath[];
  isChainProductDefined: boolean;
  chainUnits: number | null;
  chainFreeUnits: number | null;
  chainIsFreeOfCharge: boolean | null;
  isContent: boolean;
  salePrice: number;
  conseptName: string = "";
  specialDiets: string[];
  allergens: string[];
  openingHours: SalePathOpeningHours|null;
  isVisible: boolean = true;

  constructor(
    nodeType: string,
    id: number,
    code: string,
    name: string,
    infoText: string,
    description: string,
    parentID: number | null,
    productID: number | null,
    productCode: string,
    orderNumber: number,
    isOptional: boolean,
    imagePath: string,
    chainUnits: number | null,
    chainFreeUnits: number | null,
    chainIsFreeOfCharge: boolean | null,
    isContent: boolean,
    salePrice: number,
    specialDiets: string[],
    allergens: string[],
    openingHours: SalePathOpeningHours|null,
    ) {
    super(id, code, name);
    this.nodeType = nodeType;
    this.infoText = infoText;
    this.description = description;
    this.parentID = parentID;
    this.productID = productID;
    this.productCode = productCode;
    this.orderNumber = orderNumber;
    this.isOptional = isOptional;
    this.imagePath = imagePath;
    this.children = [];
    this.isChainProductDefined = chainUnits !== null;
    this.chainUnits = chainUnits == null ? null : chainUnits === 0 ? 1 : chainUnits;
    this.chainFreeUnits = chainFreeUnits;
    this.chainIsFreeOfCharge = chainIsFreeOfCharge;
    this.isContent = isContent;
    this.salePrice = (isContent || (chainIsFreeOfCharge||false)===true)  ? 0 : salePrice;
    this.specialDiets = specialDiets;
    this.allergens = allergens;
    this.openingHours = openingHours;
    this.checkVisibility();
  }

  static Create = (name: string, imagePath: string, orderNumber: number = 0) : SalePath => {
    return new SalePath('', 0, '', name, '', '', null, null, '', orderNumber, true, imagePath, null, null, null, false, 0, [], [], null)
  }

  static convertAll = (items: any[]): SalePath[] => {
    const rootItems: SalePath[] = items.filter(i => i.parentID === null).map((m) => SalePath.convert(m));
    const setChildren = (parent: SalePath, conseptName: string): void => {
      parent.children = items.filter(i => i.parentID === parent.id).map((m) => SalePath.convert(m));
      parent.children.forEach(i => {
        i.parent = parent;
        i.conseptName = conseptName;
        setChildren(i, conseptName);
      });
    }
    rootItems.forEach(i => {
      setChildren(i, i.name);
    });
    //if (rootItems.length === 1) return rootItems[0].children;
    return rootItems;
  };  

  static convert = (item: any): SalePath => {
    return new SalePath(
      item.nodeType,
      item.salePathID,
      item.salePathCode,
      item.salePathName,
      item.infoText,
      item.description,
      item.parentID,
      item.productID,
      item.productCode,
      item.orderNumber,
      item.isOptional,
      item.imagePath,
      item.chainUnits,
      item.chainFreeUnits,
      item.chainIsFreeOfCharge,
      item.isContent,
      item.salePrice,
      item.specialDiets,
      item.allergens,
      SalePathOpeningHours.convert(item.openHours),
    );
  };  

  static findByID = (id: number, collection: SalePath[]): SalePath | null=> {
    collection.forEach((item: SalePath) => {
      if (item.id === id)
      return item;
      if (item.hasChildren()){
        const subItem: SalePath | null = this.findByID(id, item.children);
        if (subItem !== null)return subItem;
      }
    });
    return null;
  };  

  public hasProduct = () : boolean => { return SalePath.hasProduct(this); }
  static hasProduct = (item:SalePath) : boolean => {
    return item.productCode !== null && item.productCode.length > 0;
  }

  public hasParentProduct = () : boolean => { return SalePath.hasParentProduct(this); }
  static hasParentProduct = (item:SalePath) : boolean => {
    if (item.parent == null)return false;
    return SalePath.hasProduct(item.parent);
  }

  public hasChildren = () : boolean => { return SalePath.hasChildren(this); }
  static hasChildren = (item:SalePath) : boolean => {
    return item.children !== null && item.children.length > 0;
  }

  public isProperty = () : boolean => { return SalePath.isProperty(this); }
  static isProperty = (item:SalePath) : boolean => {
    return SalePath.hasParentProduct(item) && !SalePath.hasChildren(item);
  }

  public isChild = () : boolean => { return SalePath.isChild(this); }
  static isChild = (item:SalePath) : boolean => {
    return SalePath.hasParentProduct(item) && SalePath.hasProduct(item) && SalePath.hasChoices(item);
  }

  public hasOptions = () : boolean => { return SalePath.hasOptions(this); }
  static hasOptions = (item:SalePath) : boolean => {
    return SalePath.hasChildren(item);
  }

  public hasChoices = () : boolean => { return SalePath.hasChoices(this); }
  static hasChoices = (item:SalePath) : boolean => {
    return SalePath.hasParentProduct(item) && item.isChainProductDefined && SalePath.hasChildren(item);
  }
  
  public isSelectOne = () : boolean => { return SalePath.isSelectOne(this); }
  static isSelectOne = (item:SalePath) : boolean => {
    return SalePath.hasChoices(item) && (item.chainFreeUnits !== null && item.chainFreeUnits === 1);
  }
  
  public isSelectMany = () : boolean => { return SalePath.isSelectMany(this); }
  static isSelectMany = (item:SalePath) : boolean => {
    return SalePath.hasChoices(item) && (item.chainFreeUnits !== null && item.chainFreeUnits > 1);
  }

  public isMainProduct = () : boolean => { return SalePath.isMainProduct(this); }
  static isMainProduct = (item:SalePath) : boolean => {
    return SalePath.hasProduct(item) && !SalePath.hasParentProduct(item);
  }

  public isChoice = () : boolean => { return SalePath.isChoice(this); }
  static isChoice = (item:SalePath) : boolean => {
    return SalePath.hasParentProduct(item) && item.isChainProductDefined && !SalePath.hasChildren(item);
  }

  public isExtraSale = () : boolean => { return SalePath.isExtraSale(this); }
  static isExtraSale = (item:SalePath) : boolean => {
    return !SalePath.isMainProduct(item) && !item.isChainProductDefined;
  }
  
  public isEditable = () : boolean => { return SalePath.isEditable(this); }
  static isEditable = (item:SalePath) : boolean => {
    return SalePath.hasChildren(item) && item.children.filter(c => c.isOptional && c.chainUnits !== null).length > 0;
  }
  
  public hasExtraSale = () : boolean => { return SalePath.hasExtraSale(this); }
  static hasExtraSale = (item:SalePath) : boolean => {
    return SalePath.hasChildren(item) && item.children.filter(c => c.isOptional && !c.isChainProductDefined).length > 0;
  }

  public isRoot = () : boolean => { return SalePath.isRoot(this); }
  static isRoot = (item:SalePath) : boolean => {
    return !item.parent;
  }
  

  public checkVisibility = () => { return SalePath.checkVisibility(this); }
  static checkVisibility = (item:SalePath) => {
    if (item.openingHours){
      item.isVisible = item.openingHours.isOpen();
    } else {
      item.isVisible = true;
    }
    //console.log('checkVisibility', item.name, item.isVisible?'Open':'Closed');
  }

  public getRoot = () : SalePath => { return SalePath.getRoot(this); }
  static getRoot = (item:SalePath) : SalePath => {
    var parent:SalePath|null = item.parent;
    var prev:SalePath = item;
    while(parent!=null){
      prev = parent;
      parent = parent.parent;
    }
    return prev;
  }

  public getRootChain = () : SalePath[] => { return SalePath.getRootChain(this); }
  static getRootChain = (item:SalePath) : SalePath[] => {
    var chain: SalePath[] = [];
    var parent:SalePath|null = item.parent;
    chain.push(item);
    while(parent!=null){
      chain = [parent, ...chain]
      parent = parent.parent;
    }
    return chain;
  }

  public static sort  =(array:SalePath[]) : SalePath[] => {
    return array.sort((n1: SalePath, n2: SalePath) => n1.orderNumber - n2.orderNumber);
  }

  static cumulatePrice = (item:SalePath) : number => {
    var price =  (item.chainIsFreeOfCharge || item.isOptional) ? 0 : item.salePrice;

    if (item.children.length>0 && !item.isContent){
      for (let index = 0; index < item.children.length; index++) {
        const child = item.children[index];
        //console.log('showPrice', 'scan', child.name, child.salePrice, child);
        price += SalePath.cumulatePrice(child);
      }
    }
    return price;
  }


  public showPrice = () : string => { return SalePath.showPrice(this);}
  static showPrice = (item:SalePath) : string => {
    //console.log('showPrice', item.name, item.salePrice, item);
    return formatMoney(SalePath.cumulatePrice(item));
  }

  public toString = () : string => {
    return `SalePath (${this.id}): ${this.name}`;
  }
}
