import { FC, useContext, useRef, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography, useTheme } from '@mui/material';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import Button from '../../Controls/Button';
import { getCompanyConfigFlag } from '../../../../companyConfig/companyConfig';
import QRCode from '../../Controls/QRCode';
import Container from '../../Controls/Container';
import Config from '../../../../hooks/Config';
import { DeliveryMethodEnum } from '../../../../helpers/basketApi';
import ILocalSettings from '../../../../models/localSettings';
import { localSettings } from '../../../../helpers/localStorage';

interface Props {
}

const Paid: FC<Props> = () => {

  const {state, dispatch} = useContext(KioskContext);
  const theme = useTheme();
  const {t} = useTranslation();
  const { screenSize } = Config();
  const [mobileUrl] = useState<string>( (getCompanyConfigFlag('UI.Mobile.FollowUp', false) && state.cart?.state.toLowerCase() === 'finalized') ? `${(process.env.REACT_APP_MOBILE_URI || '')}?shop=${state.cart.shop.shopCode}&cart=${state.cart?.id || ''}` : '');
  const [settings] = useState<ILocalSettings>(localSettings.getSettings());
  const areaRef = useRef<HTMLDivElement>(null);

  const clear = () => {
    dispatch({type: KioskActionsEnum.REMOVE_ORDER});
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.collect});
  }

  const deliveryMethod: DeliveryMethodEnum = state.cart?.deliveryMethod||DeliveryMethodEnum.FrontCounter;
  const instructionSource:string = (deliveryMethod === DeliveryMethodEnum.TableServing || deliveryMethod === DeliveryMethodEnum.TakeawayTableServing)?'table':'counter';
  const instruction: {id: number, title: string}[] = [
    {id: 1, title: t(`instruction-${instructionSource}.first`)},
    {id: 2, title: t(`instruction-${instructionSource}.second`)},
    {id: 3, title: t(`instruction-${instructionSource}.third`)},
  ]

  const orderNumber: string = state.cart?.serviceID || '-';

  const width:number = screenSize.getWidth(900);
  const marginTitle: number = screenSize.getWidth(40);
  const marginBottom: number = screenSize.getWidth(80);

  const fontTitle:number = screenSize.getFontSize('h1');
  const fontSubtitle:number = screenSize.getFontSize('h2');
  const fontBody:number = screenSize.getFontSize('subtitle1');

  const widthService:number = screenSize.getWidth(400);
  const heightService:number = screenSize.getWidth(150);
  const marginService: number = screenSize.getWidth(40);

  const widthInstruction:number = screenSize.getWidth(750);
  const gapInstruction:number = screenSize.getWidth(40);
  const marginInstruction:number = screenSize.getWidth(15);
  const ballSize:number = screenSize.getWidth(60);

  const widthFooterSide:number = screenSize.getWidth(200);

  const marginButton: number = screenSize.getWidth(20);

  const sizeCode:number = screenSize.getWidth(150);
  const marginCode:number = screenSize.getWidth(20);
  const topCode:number = areaRef.current ? (areaRef.current.offsetHeight + areaRef.current.offsetTop) - sizeCode - marginCode : 0;
  const leftCode:number = areaRef.current ? (areaRef.current.offsetWidth + areaRef.current.offsetLeft) - sizeCode - marginCode : 0;

  // if (areaRef.current){
  //   console.log('areaRef', `${areaRef.current.clientWidth} x ${areaRef.current.clientHeight}`, `${areaRef.current.offsetWidth} x ${areaRef.current.offsetHeight}`);
  //   console.log('margins', `${areaRef.current.clientLeft} / ${areaRef.current.offsetLeft}`, `${areaRef.current.clientTop} x ${areaRef.current.offsetTop}`);
  //   console.log('topCode', `${areaRef.current.clientHeight} - ${areaRef.current.clientTop} - ${sizeCode} - ${marginCode}`, topCode);
  //   console.log('leftCode', `${areaRef.current.clientWidth} - ${areaRef.current.clientLeft} - ${sizeCode} - ${marginCode}`, leftCode);
  // }
  
  return (
    <div ref={areaRef} className={styles.paid} style={{paddingBottom: marginBottom}}>

      <div className={styles.title} style={{width: width, marginTop: marginTitle}}><Typography variant="h2" fontSize={fontTitle}>{t('the-order-is-paid')}</Typography></div>
      <div className={styles.service} style={{width: widthService}}>
        <Typography variant="h2" fontSize={fontSubtitle} style={{marginBottom: marginService}}>{t('pickup-number')}</Typography>
        <Container>
          <div className={styles.serviceId} style={{height: heightService}} ><Typography variant="h2" fontSize={fontTitle}>{orderNumber}</Typography></div>
        </Container>
      </div>

      <div className={styles.instruction} style={{gap: gapInstruction}}>
        {instruction.flatMap(({id, title}) => {
          if (title==='')return null;
          return (
          <Container>
            <div key={id} className={styles.instructionItem} style={{width: widthInstruction}}>
              <div className={styles.instructionBall} style={{width: ballSize, height: ballSize, minWidth: ballSize, minHeight: ballSize, margin: marginInstruction, backgroundColor: theme.palette.primary.main, color: theme.palette.background.default}}>
                <Typography variant="subtitle1" fontSize={fontSubtitle} fontWeight={600}>{id}</Typography>
              </div>
              <Typography variant="subtitle1" fontSize={fontBody} fontWeight={600} style={{textAlign: 'left'}}>{title}</Typography>
            </div>
          </Container>
          );
        }
        )}
      </div>

      <div className={styles.footer}>
        <div className={styles.footerSide} style={{width: widthFooterSide}}>
        </div>
        <div className={styles.buttons}>
          <Typography variant="h2" fontSize={fontSubtitle} style={{marginBottom: marginButton}}>{t('enjoy-your-meal')}</Typography>
          <Button buttontype='Primary' onClick={clear} className={styles.button} timer={settings.timerPaidButton}>
            <Typography variant="button" >{t('button.return-to-start')}</Typography>
          </Button>
        </div>
        <div className={styles.footerSide} style={{width: widthFooterSide}}>
          {mobileUrl!=='' &&
          <div className={styles.code} style={{left: leftCode, top: topCode}}>
            <Typography variant="h2" fontSize={fontBody} style={{width: sizeCode}}>{t('mobile.follow')}</Typography>
            <QRCode url={mobileUrl} size={sizeCode} />
          </div>}
        </div>
      </div>
    </div>

  );
};

export default Paid;
