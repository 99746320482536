import { ErrorInfo, FC, useContext, useState,  } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ContainerButton from '../../Controls/ContainerButton';
import Config from '../../../../hooks/Config';
import { DeliveryMethodEnum, setDeliveryMethod } from '../../../../helpers/basketApi';
import Cart from '../../../../models/cart';

interface Props {
}

const Servicing: FC<Props> = () => {

  const { screenSize } = Config();
  const {state, dispatch} = useContext(KioskContext);
  const {t} = useTranslation();
  const [servingChoices] = useState<DeliveryMethodEnum[]>(Cart.getChoicesForTableServicing(state.cart))

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const setMethod = async (method: DeliveryMethodEnum) => {
    if (state.cart){
      if (state.cart.deliveryMethod !== method){
        const cart = await setDeliveryMethod(state.cart.id, method);
        dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      }
    }
  };

  const proceed = (method: DeliveryMethodEnum) => {
    if (state.cart == null)return;
    const cartMethod: DeliveryMethodEnum = state.cart.deliveryMethod;
    if (method === DeliveryMethodEnum.TableServing) {
      setMethod(cartMethod === DeliveryMethodEnum.FrontCounter ? DeliveryMethodEnum.TableServing : DeliveryMethodEnum.TakeawayTableServing);
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.tableNumber});
      return;
    }
    dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.confirmOrder});
  }

  const allowFrontCounter = servingChoices.find(c=>c===DeliveryMethodEnum.FrontCounter);
  const allowTableServing = servingChoices.find(c=>c===DeliveryMethodEnum.TableServing);

  const buttonWidth: number = screenSize.getWidth(800);
  const buttonHeight: number = screenSize.getWidth(150);
  const buttonFontSize: number = screenSize.getFontSize('h1');
  const buttonGap: number = screenSize.getWidth(70);
  
  return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Servicing', error, info)}>
    <div className={styles.placeToEat} style={{}}>
      <div className={styles.buttons} style={{gap: buttonGap}}>
        {allowFrontCounter && 
          <ContainerButton onClick={() => proceed(DeliveryMethodEnum.FrontCounter)}>
            <div className={styles.btn} style={{width: buttonWidth, height: buttonHeight}}>
              <Typography variant='h2' fontSize={buttonFontSize}>{t('button.deliverymethod-frontcounter')}</Typography>
            </div>
          </ContainerButton>
        }
        {allowTableServing &&
          <ContainerButton onClick={() => proceed(DeliveryMethodEnum.TableServing)}>
            <div className={styles.btn} style={{width: buttonWidth, height: buttonHeight}}>
              <Typography variant='h2' fontSize={buttonFontSize}>{t('button.deliverymethod-table')}</Typography>
            </div>
          </ContainerButton>
        }
      </div>
    </div>
  </ErrorBoundary>
  );
};

export default Servicing;
