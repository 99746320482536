import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../../models/EditItem';
import ProductSelectItem from '../ProductSelectItem';
import Config from '../../../../../hooks/Config';

interface Props {
  parent: EditItem;
  choices?: EditItem[];
}

const ProductSelectManyChoices: FC<Props> = ({parent, choices}) => {

  const items = choices || parent.children;
  const [ selectionArray ] = useState<EditItem[]>(items.filter(c => c.quantity !== 0));
  const [ refreshing, refresh ] = useState<boolean>(false);
  const { screenSize } = Config();

  const freeCount:number = parent.salePath.chainFreeUnits || 0;

  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  const isPriced = (item: EditItem): boolean => {
    if (freeCount<1)return item.salePath.salePrice !== 0;
    const index: number = selectionArray.indexOf(item, 0);
    if (index<0){
      if (selectionArray.length>=freeCount) return true;
    } else {
      if (index>=freeCount)return true;
    }
    return false;
  };

  const changed = (item: EditItem, checked: boolean) => {
    if (item.quantity === 0){
      selectionArray.splice(selectionArray.indexOf(item),1);
    } else {
      selectionArray.push(item);
    }
    items.forEach(c => c.forcedPricing=false);
    for(let i:number=0;i<selectionArray.length;i++){
      selectionArray[i].forcedPricing = freeCount===0? selectionArray[i].salePath.salePrice !== 0 : selectionArray[i].quantity !== 0 && i>=freeCount;
    }
    refresh(true);
  };

  const gap: number = screenSize.getWidth(20);

  return (
    <div className={styles.productSelect} style={{gap: gap}}>
      {EditItem.sort(items).map((choice:EditItem, i:number)=>(
        <ProductSelectItem key={i} title={choice.salePath.name} editItem={choice} disabled={!choice.salePath.isOptional} showPrice={isPriced(choice)} onChange={changed} />
      ))}
    </div>
    );
};

export default ProductSelectManyChoices;
