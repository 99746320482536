import { FC, ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import ContainerButton from '../ContainerButton';
import Container from '../Container';
import Config from '../../../../hooks/Config';
import { Typography, useTheme } from '@mui/material';
import { IconStore } from '../../../../icons/iconStorage';

interface Props {
  value: number|undefined;
  maxValue?: number;
  enabled?: boolean;
  showValue?: boolean;
  noValue?: string;
  onValueChange?: (value: number|undefined) => void;
}

const NumericEntry: FC<Props> = ({value, maxValue=999, enabled=true, showValue = false, noValue, onValueChange}) => {

  const [ numericValue, setNumericValue ] = useState<number|undefined>(value);
  const { screenSize } = Config();
  const theme = useTheme();

  const isNumber = (value:string) : boolean => {
    return value.match('[0-9]') != null;
  }
  const onButtonPress = (value:string) => {
    var numeric:number|undefined = numericValue;
    
    if (isNumber(value)){
      numeric = (numeric||0)*10+Number.parseInt(value);
    } else {
      switch(value){
        case 'CLR':{
          numeric = undefined;
          break;
        }
        case 'DEL':{
          numeric = Math.floor((numeric||0)/10);
          if (numeric===0)numeric=undefined;
          break;
        }
      }
    }
    if (numeric&&numeric > maxValue)return;
    setNumericValue(numeric);
    if (onValueChange)onValueChange(numeric);
  }
  
  const valueWidth: number = screenSize.getWidth(700);
  const valueHeight: number = screenSize.getWidth(150);
  const valueFont: number = screenSize.getFontSize('h1')
  const valueMargin: number = screenSize.getWidth(50);
  const numpadWidth: number = screenSize.getWidth(600);
  const buttonFont: number = screenSize.getFontSize('h1')
  const iconSize: number = screenSize.getFontSize('h1');

  const renderButton = (value:string) : ReactNode => {
    var node:ReactNode|undefined;

    if (isNumber(value)){
      // node = (<div className={styles.buttonText} style={{fontSize: buttonFont}}>{value}</div>);
      node = (<Typography variant='h2' className={styles.buttonText} fontSize={buttonFont}>{value}</Typography>);
    }
    switch(value){
      case 'CLR':{
        node = (<IconStore iconName='Clear' size={iconSize}/>);
        break;
      }
      case 'DEL':{
        node = (<IconStore iconName='Backspace' size={iconSize}/>);
        break;
      }
    }
    if (node){
      return (
        <ContainerButton className={styles.control} onClick={() => {onButtonPress(value)}}>
          <div className={styles.button} >
            {node}
          </div>
        </ContainerButton>
      );
    }
    return (<></>);
  }
  
  return (
    <div className={styles.numericEntry}>
      {showValue && (
        <>
        <Container component="section">
          <div className={styles.value} style={{width: valueWidth, height: valueHeight, color: value ? theme.palette.text.primary : theme.palette.text.disabled}}>
            <Typography variant="h2" sx={{fontSize: valueFont}}>{value ? value : noValue||''}</Typography>
          </div>
        </Container>
        <div style={{height: valueMargin}}></div>
      </>
    )}
      <div className={styles.controls} style={{width: numpadWidth}}>
      <table>
        <colgroup>
        <col className={styles.column}/>
        <col className={styles.column}/>
        <col className={styles.column}/>
        </colgroup>
        <tbody>
          <tr><td>{renderButton('1')}</td>
          <td>{renderButton('2')}</td>
          <td>{renderButton('3')}</td></tr>
          <tr><td>{renderButton('4')}</td>
          <td>{renderButton('5')}</td>
          <td>{renderButton('6')}</td></tr>
          <tr><td>{renderButton('7')}</td>
          <td>{renderButton('8')}</td>
          <td>{renderButton('9')}</td></tr>
          <tr><td>{renderButton('CLR')}</td>
          <td>{renderButton('0')}</td>
          <td>{renderButton('DEL')}</td></tr>
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default NumericEntry;
