import { FC } from 'react';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import Button from '../Button';
import Config from '../../../../hooks/Config';

interface Props {
  count: number;
  minCount?: number;
  maxCount?: number;
  enabled?: boolean;
  onChange?: (count: number) => void;
}

const SetCount: FC<Props> = ({count, minCount=1, maxCount=9, enabled=true, onChange}) => {

  const { screenSize } = Config();

  const setCount = (count: number) => {
    if ((count>=minCount && count <= maxCount) && onChange) {
      onChange(count);
    }
  }

  const canDecrease = () : boolean => {
    return enabled && count>minCount;
  }
  const decreaseCount = () => {
    if (canDecrease()) {
      setCount(count-1);
    }
  }
  const canIncrease = () : boolean => {
    return enabled && count<maxCount;
  }
  const increaseCount = () => {
    if (canIncrease()) {
      setCount(count+1);
    }
  }

  const fontSize: number = screenSize.getFontSize('h2');
  const buttonSize: number = screenSize.getWidth(5);
  const buttonGap: number = screenSize.getWidth(25);

  const buttonStyle = {width: buttonSize, height: buttonSize};

  return (
    <div className={styles.countButtons} style={{gap: buttonGap}}>
      <Button buttontype={canDecrease() ? 'Tertiary' : 'Tertiary'} onClick={decreaseCount} className={styles.countButton} style={buttonStyle}>-</Button>
      <Typography variant="body1" className={styles.countText} sx={{fontSize: fontSize}}>{count}</Typography>
      <Button buttontype={canIncrease() ? 'Tertiary' : 'Tertiary'} onClick={increaseCount} className={styles.countButton} style={buttonStyle}>+</Button>
    </div>
  );
};

export default SetCount;
