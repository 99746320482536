import * as React from 'react';
//import { getLocalSettings, setLocalSettings, } from '../../helpers/localStorageHelper';

import {
  KioskActionsEnum,
  KioskReducer,
  IKioskContext,
  OrderStepsEnum,
  StepsEnum,
} from "./types";
import SalePathData from '../../models/SalePathData';
import EditData from '../../models/EditData';
import ILocalSettings from '../../models/localSettings';
import EditItemNode from '../../models/EditItemNode';
import { localSettings } from '../../helpers/localStorage';

export const initialState: IKioskContext = {
  operator: null,
  settings: localSettings.getSettings(),
  isBrowsing: true,
  orderSteps: 0,
  isDataLoaded: false,
  isConfirmingBasket: false,
  salePath: null,
  selectedNode: null,
  subTitleText: 'start',
  salePathData: new SalePathData([]),
  editData: null,
  cart: null,
  step: StepsEnum.collect,
  loadedAt: null,
  paymentPending: false,
  paymentInProcess: null,
  observedCartId: null,
  showReceiptCartId: null,
  showReceiptStatusCartId: null,
  additionalItems: null,
  ignoreLatest: false,
};


export const KioskContext = React.createContext<{
  state: IKioskContext;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer: KioskReducer = (state: IKioskContext, action: any) => {

  switch (action.type) {
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
    
    case KioskActionsEnum.LOADED: {
      return {
        ...state,
        isDataLoaded: true,
        salePathData: new SalePathData(action.salePaths),
        editData: null,
        loadedAt: new Date(),
      };
    }

    case KioskActionsEnum.LOGIN_USER: {
      return {
        ...state,
        operator: action.operator,
        inactive: false,
      };
    }

    case KioskActionsEnum.SET_SETTINGS: {
      const data: ILocalSettings = action.settings;
      localSettings.setSettings(data);
      return {
        ...state,
        settings: data,
      };
    }

    case KioskActionsEnum.SET_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }

    case KioskActionsEnum.SET_NEXT_STEP: {
      const step:number = state.step + (state.step < StepsEnum.paid ? 1 : 0);
      return {
        ...state,
        step: step,
      };
    }

    case KioskActionsEnum.SET_PREVIOUS_STEP: {
      let step:number = state.step - (state.step> 0 ? 1 : 0);
      if (step === StepsEnum.cart) step = StepsEnum.collect;
      return {
        ...state,
        step: step,
      };
    }

    case KioskActionsEnum.SHOW_CART: {
      return {
        ...state,
        step: StepsEnum.cart
      };
    }
    
    case KioskActionsEnum.HIDE_CART: {
      return {
        ...state,
        step: StepsEnum.collect
      };
    }

    case KioskActionsEnum.TOGGLE_CART: {
      if (state.step === StepsEnum.collect && state.cart == null) return state;
      const step:number = state.step > StepsEnum.confirmOrder ? state.step : state.step === StepsEnum.cart ? StepsEnum.collect : StepsEnum.cart;
      return {
        ...state,
        step: step
      };
    }

    case KioskActionsEnum.OBSERVE_CART: {
      return {
        ...state,
        observedCartId: action.cartId,
        showReceiptCartId: null,
        showReceiptStatusCartId: null,
      };
    }

    case KioskActionsEnum.RESET_CART: {
      return {
        ...state,
        step: StepsEnum.collect,
        salePath: null,
        editData: null,
        cart: null,
        ignoreLatest: true,
      };
    }

    case KioskActionsEnum.SHOW_RECEIPT: {
      return {
        ...state,
        showReceiptCartId: action.cartId,
        showReceiptStatusCartId: null,
      };
    }

    case KioskActionsEnum.SHOW_RECEIPTSTATUS: {
      return {
        ...state,
        showReceiptCartId: null,
        showReceiptStatusCartId: action.cartId,
      };
    }



    case KioskActionsEnum.TIMEOUT: {
      return {
        ...state,
        isConfirmingBasket: false,
        salePath: null,
        editData: null,
        cart: null,
        subTitleText: '',
        orderSteps: 0
      };
    }

    case KioskActionsEnum.RELOAD: {
      return {
        ...state,
        isDataLoaded: false,
      };
    }

    case KioskActionsEnum.UPDATE_CART: {
      return {
        ...state,
        cart: action.cart,
        ignoreLatest: true,
      };
    }

    case KioskActionsEnum.SELECT_NODE: {
      let editData: EditData | null = null;
      if (action.salePath.isMainProduct()) editData = new EditData(action.salePath, action.product);
      const node = editData ? new EditItemNode(editData.rootItem):null;
      return {
        ...state,
        salePath: action.salePath,
        selectedNode: node,
        editData: editData,
      };
    }
    
    case KioskActionsEnum.SELECT_PARENT: {
      return {
        ...state,
        salePath: state.salePath ? state.salePath.parent : null,
      };
    }
    
    case KioskActionsEnum.SELECT_ROOT: {
      return {
        ...state,
        salePath: null,
        subTitleText: 'start',
      };
    }
    
    case KioskActionsEnum.SHOW_BASKET: {
      return {
        ...state,
        isConfirmingBasket: true
      };
    }
    
    case KioskActionsEnum.HIDE_BASKET: {
      return {
        ...state,
        isConfirmingBasket: false
      };
    }
    
    case KioskActionsEnum.REMOVE_ORDER: {
      return {
        ...state,
        salePath: null,
        editData: null,
        cart: null,
      };
    }

    case KioskActionsEnum.SELECT_NEXT_STEP: {
      let subTitle: string = '';
      const step: number = state.orderSteps + 1;
      if (step > 0) subTitle = 'where-do-eat';
      if (step === 2) subTitle = 'confirm-order';
      if (step === 3) subTitle = '';
      return {
        ...state,
        subTitleText: subTitle,
        orderSteps: action.step || step,
      }
    }

    case KioskActionsEnum.SELECT_PREVIOUS_STEP: {
      let subTitle: string = '';
      const step: number = state.orderSteps - 1;
      if (step > 0) {
        subTitle = 'where-do-eat';
      }
      return {
        ...state,
        subTitleText: subTitle,
        orderSteps: step
      }
    }

    case KioskActionsEnum.SELECT_STEP: {
      return {
        ...state,
        orderSteps: action.step,
      }
    }
    
    case KioskActionsEnum.START_PAYMENT: {
      return {
        ...state,
        paymentPending: true,
        paymentInProcess: false,
      }
    }
    
    case KioskActionsEnum.PAYMENT_INPROCESS: {
      return {
        ...state,
        paymentPending: false,
        paymentInProcess: true,
      }
    }
    
    case KioskActionsEnum.PAYMENT_FAILED: {
      return {
        ...state,
        paymentPending: false,
        paymentInProcess: false,
      }
    }
    
    case KioskActionsEnum.PAYMENT_SUCCESSFUL: {
      return {
        ...state,
        subTitleText: 'thank-for-order',
        paymentPending: false,
        paymentInProcess: false,
      }
    }

    case KioskActionsEnum.ADD_ORDER_NUMBER: {
      return {
        ...state,
        orderSteps: OrderStepsEnum.STEP_FOUR,
        subTitleText: 'thank-for-order',
      }
    }
    
    case KioskActionsEnum.ORDER_COMPLETE: {
      return {
        ...state,
        orderSteps: 0,
        totalBasketPrice: null,
        subTitleText: 'start',
        salePath: null,
        editData: null,
        cart: null,
      }
    }

    case KioskActionsEnum.UPDATE_ADDITIONAL_ITEMS: {
      return {
        ...state,
        additionalItems: action.items,
      }
    }
    
  }
};

interface KioskProviderProps {
  children: React.ReactNode
}
export const KioskProvider: React.FC<KioskProviderProps> = ({ children }) => {

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <KioskContext.Provider value={{ state, dispatch }}>
      {children}
    </KioskContext.Provider>
  );
};