import { initialLocalSettings } from '../models/localSettings';
import ILocalSettings from '../models/localSettings';

class localStorageEntry<T> {         

  private key: string;
  private defaultValues: T;

  constructor(key: string, defaultValues: T ) {
    this.key = key;
    this.defaultValues = defaultValues;
  }

  private checkNewDefaults = (settings: T) : T => {
    var changed: boolean = false;
    (Object.keys(this.defaultValues as object) as Array<keyof typeof settings>).forEach(key => {
      //console.log('SettingValue', `${key.toString()}: ${settings[key]}`, `default: ${this.defaultValues[key]}`);
      if (settings[key]===undefined){
        settings[key] = this.defaultValues[key];
        changed = true;
        //console.log('SetDefaultValue', `${key.toString()}: ${settings[key]}`, `default: ${this.defaultValues[key]}`);
      }
    });
    if (changed){
      this.setSettings(settings);
    }
    return settings;
  }

  public clearSettings = () => {
    try {
      localStorage.removeItem(this.key);
    } catch (e) {
      throw new Error(`Error removing local storage entry ${this.key}: ${e}`);
    }
  };

  public setSettings = (settings: T) => {
    try {
      localStorage.setItem(this.key, JSON.stringify(settings));
    } catch (e) {
      throw new Error(`Error storing local storage entry ${this.key}: ${e}`);
    }
  };

  public getSettings = (): T => {
    const json: string | null = localStorage.getItem(this.key);
    if (json&&json.length>0){
      try {
        return this.checkNewDefaults(JSON.parse(json) as T);
      } catch (e) {
        this.clearSettings();
        this.setSettings(this.defaultValues);
        return this.defaultValues;
      }
    } else {
      this.setSettings(this.defaultValues);
      return this.defaultValues;
    }
  };
}

export const localSettings = new localStorageEntry<ILocalSettings>('posmanagerBasketKioskSettings', initialLocalSettings);
