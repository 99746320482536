import { FC, ReactNode } from 'react';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import LanguageIcon from '@mui/icons-material/LanguageOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './styles.module.scss';
import Config from '../../../../hooks/Config';
import { useTheme } from '@mui/material';

export enum IconType {
  Home = 1,
  Back,
  Basket,
  Clear,
  Language
}

interface SideMenuButtonProps {
  label: string,
  icon?: IconType,
  disabled?: boolean,
  onClick: () => void,
}

const SideMenuButton: FC<SideMenuButtonProps> = ({label, icon, disabled = false, onClick}) => {

  const theme = useTheme();
  const { screenSize } = Config();

  const clickHandler = (): void => {
    if (!disabled) {
      !disabled && onClick(); 
    }
  }

  const height: number = screenSize.getWidth(230);
  const sizeIcon:string = `${screenSize.getWidth(45)}px`;
  const sizeText:string = `${screenSize.getWidth(20)}px`;

  const lines: string[] = label.split('|');

  const renderIcon = (): ReactNode => {
    switch (icon) {
      default:
        return <></>;
      case IconType.Home:
        return <HomeOutlinedIcon style={{fontSize: sizeIcon}}/>;
      case IconType.Back:
        return <KeyboardBackspaceOutlinedIcon style={{fontSize: sizeIcon}}/>;
      case IconType.Basket:
        return <ShoppingBasketOutlinedIcon style={{fontSize: sizeIcon}}/>;
      case IconType.Clear:
        return <ClearIcon style={{fontSize: sizeIcon}}/>;
        case IconType.Language:
          return <LanguageIcon style={{fontSize: sizeIcon}}/>;
      }
  }
  
  return (
    <div onClick={clickHandler} className={`${styles.sideBarMenuItem} ${IconType[icon||IconType.Home].toLowerCase()}`}
      style={{
        height: height,
        color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
        backgroundColor: theme.palette.background.default
        }}>
      <div className={styles.itemContent}>
        {icon && renderIcon()}
        <div className={styles.itemLabel} style={{fontSize: sizeText}}>
          {lines.map((l,i) => (<span key={i}>{l}</span>))}
        </div>
      </div>
    </div>
  );
};

export default SideMenuButton;
