import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../../models/EditItem';
import DoneIcon from '@mui/icons-material/Done';
import { Typography, useTheme } from '@mui/material';
import { formatMoney } from '../../../../../helpers/formatHelper';
import { useTranslation } from 'react-i18next';
import ContainerButton from '../../ContainerButton';
import Config from '../../../../../hooks/Config';

interface Props {
  title?: string;
  editItem: EditItem;
  disabled?: boolean;
  showPrice?: boolean;
  onChange?: (item: EditItem, checked: boolean) => void;

}

const ProductSelectItem: FC<Props> = ({title = undefined, editItem, disabled=false, showPrice=false, onChange}) => {

  const theme = useTheme();
  const {t} = useTranslation();
  const { screenSize } = Config();
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  const isChecked:boolean = editItem.quantity !== 0 ? true : false;

  const height: number = screenSize.getWidth(80);
  const marginH: number = screenSize.getWidth(20);
  const marginV: number = screenSize.getWidth(10);
  const fontSize: number = screenSize.getFontSize('button');
  const fontSizeSmall: number = screenSize.getFontSize('body1');
  const iconSize: number = screenSize.getWidth(40);

  const toggleChecked = () => {
    const newQuantity:number = editItem.quantity !== 0 ? 0 : 1;
    editItem.quantity = newQuantity;
    editItem.forcedPricing = newQuantity !== 0 && editItem.salePath.salePrice !== 0;
    refresh(true);
    if (onChange){
      onChange(editItem, editItem.quantity !== 0);
    }
  }

  return (
    <ContainerButton className={styles.item} onClick={!disabled ? toggleChecked : undefined} sx={{borderColor: isChecked ? theme.palette.primary.main : 'transparent'}}
      style={{
        borderColor: isChecked ? theme.palette.primary.main : 'transparent',
        height: height,
        paddingLeft: marginH,
        paddingRight: marginH,
        paddingTop: marginV,
        paddingBottom: marginV,
        fontSize: fontSize
      }}>
      <div className={styles.title}>
        <Typography variant='body1' className={styles.name}>{title || editItem.name}</Typography>
        {showPrice && <Typography className={styles.price} style={{color: theme.palette.primary.main}}>{formatMoney(editItem.salePath.salePrice)} €</Typography>}
      </div>
      {disabled && <Typography variant='body1' className={styles.mandatory} style={{color: theme.palette.primary.main, fontSize: fontSizeSmall}} >{t('mandatory-choice')}</Typography>}
      {isChecked && <div className={styles.selected} style={{width: iconSize, height: iconSize, backgroundColor: theme.palette.primary.main}}><DoneIcon style={{fontSize: iconSize*0.8, color: theme.palette.background.default}}/></div>}
    </ContainerButton>
  );
};

export default ProductSelectItem;
