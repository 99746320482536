import EditItem from "./EditItem";
import EditItemNodeChoice from "./EditItemNodeChoice";

export enum EditItemNodeChildType {
  Node = 'Node',
  SelectOne = 'SelectOne',
  SelectMany = 'SelectMany',
  SelectProperty = 'SelectProperty',
  Included = 'Included',
  AdditionalProperty = 'AdditionalProperty'
}

export default class EditItemNodeChild {

  editItem : EditItem;
  childType : EditItemNodeChildType;
  choices: EditItemNodeChoice[];
  
  constructor(editItem: EditItem, childType: EditItemNodeChildType) {
    this.editItem = editItem;
    this.childType = childType;
    this.choices = EditItemNodeChild.buildChoices(editItem, childType);
  }

  static buildChoices = (editItem: EditItem|EditItemNodeChild, childType: EditItemNodeChildType): EditItemNodeChoice[] => {
    const result : EditItemNodeChoice[] = [];
    if (childType === EditItemNodeChildType.Node) return result;
    const parent: EditItem = editItem instanceof EditItem ? editItem : editItem.editItem;
    const items: EditItem[] = parent.children;

    items.forEach((item: EditItem) => {
      result.push(new EditItemNodeChoice(parent, item));
    });
    if (childType === EditItemNodeChildType.SelectOne){
      if (!parent.salePath.isOptional && parent.children.length > 0 && parent.children.find(i=>i.quantity>0) === undefined){
        parent.children[0].quantity = 1;
      }
    }
    // editItem.children.forEach((item: EditItem) => {
    //   result.push(new EditItemNodeChoice(editItem, item));
    // });
    // if (childType === EditItemNodeChildType.SelectOne){
    //   if (!editItem.salePath.isOptional && editItem.children.length > 0 && editItem.children.find(i=>i.quantity>0) === undefined){
    //     editItem.children[0].quantity = 1;
    //   }
    // }
    return result;
  };


}
  