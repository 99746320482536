import { ErrorInfo, FC, useContext } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../../context/KioskContext';
import Product from '../../../../models/product';
import React from 'react';
import BasketProduct from '../../Controls/BasketProduct';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ComponentDialog from '../ComponentDialog';
import Config from '../../../../hooks/Config';

interface Props {
  visible: boolean;
  product?: Product|null;
  onClose?: (remove: boolean) => void;
}

const RemoveProductDialog: FC<Props> = ({visible, product, onClose}) => {

  const {state} = useContext(KioskContext);
  const { screenSize } = Config();
  const {t} = useTranslation();

  const closing = (code: string) => {
    if (onClose)onClose(code === 'Remove');
  }

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const margin: number = screenSize.getWidth(40);

return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('LanguageDialog', error, info)}>
    <ComponentDialog visible={visible} onClose={(code:string)=> {closing(code);}}
      actions={[
        { code: 'Cancel', caption: t('button.cancel'), buttonType: 'Secondary'},
        { code: 'Remove', caption: t('button.remove'), buttonType: 'Primary'}
      ]}
      title={''}
      iconName={'Delete'}
      fullscreen={false}>
      <>
        <Typography variant='h2'>{t('remove-product')}</Typography>
        {product && 
        <div style={{marginTop: margin}}>
          <BasketProduct disableControls product={product} />
        </div>}
      </>
    </ComponentDialog>
  </ErrorBoundary>
  );
};

export default RemoveProductDialog;
