const apiUrl: string = process.env.REACT_APP_UPPROXY_URI || '';

export const postReceiptLayout = async ( layout:string ) : Promise<Error|boolean> => {
  try {
    await fetch(`${apiUrl}/api/printreceipt`, {
      method: 'post',
      mode: 'no-cors', 
      headers: {
        'Content-Type': 'application/xml',
      },
      body: layout,
    });
  } catch (error) {
    if (error instanceof Error) return error;
    return false;
  }
  return true;
};
