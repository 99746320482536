import { FC, useEffect, useState } from 'react';
import EditItem from '../../../../../models/EditItem';
import EditItemNode from '../../../../../models/EditItemNode';
import SalePath from '../../../../../models/SalePath';
import SalePathList from '../../SalePathList';
import { useTranslation } from 'react-i18next';
import { getCompanyConfig } from '../../../../../companyConfig/companyConfig';

interface Props {
  node: EditItemNode;
}

const ProductSelectOne: FC<Props> = ({node}) => {

  const {t} = useTranslation();
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  const getCode = (item: EditItem) : string => {return item.salePath.code;}

  useEffect(() => {
    const choice:EditItem|undefined = node.editItem.children.find(i => i.quantity !== 0);
    var code:string = '';
    if (choice) {
      code = choice.salePath.code;
    } else {
      if (!node.editItem.salePath.isOptional && node.children.length>0){
        code = node.children[0].editItem.salePath.code;
      }
    }
    node.editItem.children.forEach(e => {
      e.quantity = getCode(e) === code ? 1 : 0;
    });
  },[node.children, node.editItem.children, node.editItem.salePath.isOptional]);

  const setQuantities = (selected: string) => {
    node.editItem.children.forEach(e => {
      e.quantity = getCode(e) === selected ? 1 : 0;
    });
  }

  const handleSelectOne = (item: EditItem|undefined, isSelected: boolean) => {
    if (isSelected){
      const code:string = item?.salePath.code||'';
      setQuantities(code);
      refresh(true);
    }
  };

  const nodeClicked = (item: SalePath) => {
    if (item.code===''){
      handleSelectOne(undefined, true);
    } else {
      const editItem:EditItem|undefined = node.editItem.children.find(i => i.salePath.code === item.code);
      if (!editItem)return;
      if (editItem.quantity === 0){
        handleSelectOne(editItem, true);
      }
    }
  }

  const resolveChoices = () : SalePath[] => {
    const result: SalePath[] = [];
    if (node.editItem.salePath.isOptional){
      result.push(SalePath.Create(t('not-selected'), node.editItem.salePath.imagePath, -1));
    }
    return result.concat(node.editItem.children.map(e=>e.salePath));
  }

  const selected: SalePath[] = node.editItem.children.filter(e=>e.quantity!==0).map(e=>e.salePath);
  const columnCount: number = Number.parseInt(getCompanyConfig('UI.Select.Columns', '4'));

  return (
    <div>
      <SalePathList columnCount={columnCount} parent={node.editItem.salePath} selectEnabled={true} choices={resolveChoices()} selected={selected} onClick={(node: SalePath) => nodeClicked(node)} />
    </div>
  );
};
    
export default ProductSelectOne;
