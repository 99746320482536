import { FC, useState, useContext } from 'react';
import SideMenuButton, { IconType } from './SideMenuButton';
import { KioskContext } from '../../../context/KioskContext';
import { KioskActionsEnum, OrderStepsEnum, StepsEnum } from "../../../context/KioskContext/types";
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../../helpers/formatHelper";
import { useTheme } from '@mui/material';
import LanguageDialog from '../Dialogs/LanguageDialog';
import ClearCartDialog from '../Dialogs/ClearCartDialog';
import Cart from '../../../models/cart';

const SideMenu: FC = () => {
  const {state, dispatch} = useContext(KioskContext);
  const theme = useTheme();
  const [languageDialogShow, setLanguageDialogShow] = useState<boolean>(false);
  const [confirmResetShow, setConfirmResetShow] = useState<boolean>(false);
  const {t} = useTranslation();
  const { i18n } = useTranslation('home');

  const onRootClick = (): void => {
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.collect});
  }

  const onBackClick = (): void => {
    const stepCurrent: StepsEnum = state.step;
    let stepGoto: StepsEnum|undefined = undefined;
    switch (state.step) {
      case StepsEnum.collect: {
        dispatch({type: KioskActionsEnum.SELECT_PARENT});
        return;
      }
      case StepsEnum.cart: {stepGoto = StepsEnum.collect;break;}
      case StepsEnum.placeToEat: {stepGoto = StepsEnum.cart;break;}
      case StepsEnum.servicing: {
        if (state.cart && Cart.getChoicesForPlaceToEat(state.cart).length > 1) {
          stepGoto = StepsEnum.placeToEat;
        } else {
          stepGoto = StepsEnum.cart;
        }
        break;
      }
      case StepsEnum.tableNumber: {
        if (state.cart && Cart.getChoicesForTableServicing(state.cart).length > 1) {
          stepGoto = StepsEnum.servicing;
        } else if (state.cart && Cart.getChoicesForPlaceToEat(state.cart).length > 1) {
          stepGoto = StepsEnum.placeToEat;
        } else {
          stepGoto = StepsEnum.cart;
        }
        break;
      }
      case StepsEnum.confirmOrder: {
        if ((state.cart?.servingID||'') !== '') {
          stepGoto = StepsEnum.tableNumber;
        } else if (state.cart && Cart.getChoicesForTableServicing(state.cart).length > 1) {
          stepGoto = StepsEnum.servicing;
        } else if (state.cart && Cart.getChoicesForPlaceToEat(state.cart).length > 1) {
          stepGoto = StepsEnum.placeToEat;
        } else {
          stepGoto = StepsEnum.cart;
        }
        break;
      }
    }
    if (stepGoto === undefined) stepGoto = stepCurrent - 1;
    if (stepCurrent!==stepGoto){
      dispatch({type: KioskActionsEnum.SET_STEP, step:stepGoto});
    }
  }

  const onBasketClick = (): void => {
    dispatch({ type: KioskActionsEnum.SET_STEP, step:StepsEnum.cart });
  }

  const onEmptyBasketClick = (): void => {
    if (paymentInProgress===true)return;
    if ((state.cart?.products.length||0) > 0){
      setConfirmResetShow(true);
    } else {
      onRootClick();
    }
  }

  const hideStep: boolean = state.orderSteps > 4;
  const receiptInProgress: boolean = (state.cart?.products.length||0) > 0;
  const paymentInProgress: boolean = (state.paymentInProcess!==null&&state.paymentInProcess===true);

  const disableHome: boolean = state.salePath == null && state.step === 0;
  const disableBack: boolean = state.salePath == null && state.step === 0;

  return (
    <>
      <div className={styles.sideBarMenu} style={{color: theme.palette.text.primary}}>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
          {state.orderSteps  < 5 &&
            <SideMenuButton 
              disabled={disableBack} 
              label={t('button.back')} 
              icon={IconType.Back} 
              onClick={onBackClick}
            />
          }
        </div>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
        {state.orderSteps < 5 &&
            <SideMenuButton
              label={`${t('button.order')} ${state.cart ? `|${formatMoney(state.cart.receiptTotal)} €` : ''}`}
              icon={IconType.Basket}
              disabled={!(receiptInProgress)}
              onClick={onBasketClick}
            />
          }
        </div>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
        {state.orderSteps < 5 &&
            <SideMenuButton 
              disabled={disableHome} 
              label={t('button.home')} 
              icon={IconType.Home} 
              onClick={onRootClick}
            />
          }
        </div>
        <div className={`${hideStep ? styles.hideStep : ''}`}>
        {state.orderSteps < 5 &&
            <SideMenuButton 
              disabled={state.salePath == null && state.cart == null} 
              label={`${t('button.language')}|${i18n.language.toUpperCase()}`} 
              icon={IconType.Language} 
              onClick={() => {setLanguageDialogShow(true)}}
            />
          }
        </div>
        <div 
          className={`
          ${state.orderSteps === OrderStepsEnum.STEP_SIX ? styles.hideStep : ''} 
          ${state.orderSteps === OrderStepsEnum.STEP_FIVE ? styles.changeBorderStep : ''}
              `}
        >
          {state.orderSteps !== OrderStepsEnum.STEP_SIX &&
            <SideMenuButton
              label={t('button.return-to-start')}
              icon={IconType.Clear}
              disabled={ !(receiptInProgress||paymentInProgress||state.salePath!==null)}
              onClick={onEmptyBasketClick}
            />
          }
        </div>
      </div>
      <LanguageDialog visible={languageDialogShow} onClose={() => setLanguageDialogShow(false)}/>
      <ClearCartDialog visible={confirmResetShow} onClose={() => setConfirmResetShow(false)}/>
    </>
  );
};

export default SideMenu;
