import { DeliveryMethodEnum, OrderStateEnum } from '../helpers/basketApi';
import { formatMoney } from '../helpers/formatHelper';
import Customer from './customer';
import Operator from './operator';
import Parameter from './parameter';
import Payment from './payment';
import Product from './product';
import Shop from './shop';

export default class Cart {
  id: string;

  orderId: string;

  receiptNumber: number;

  products: Array<Product>;

  payments: Array<Payment>;

  customer: Customer;

  posNumber: number;

  operator: Operator;

  receiptInfo: string;

  receiptTotal: number;

  shop: Shop;

  posParameters: Array<Parameter>;

  serviceID: string;

  email: string;

  state: string;

  deliveryMethod: DeliveryMethodEnum;

  pickUpTime: string;

  orderState: OrderStateEnum;

  registrationNumber: string;

  tableNumber: string;

  servingID: string;

  constructor(
    id: string,
    orderId: string,
    receiptNumber: number,
    products: Array<Product>,
    payments: Array<Payment>,
    customer: Customer,
    posNumber: number,
    operator: Operator,
    receiptInfo: string,
    shop: Shop,
    receiptTotal: number,
    posParameters: Array<Parameter>,
    serviceID: string,
    email: string,
    state: string,
    deliveryMethod: DeliveryMethodEnum,
    pickUpTime: string,
    orderState: OrderStateEnum,
    registrationNumber: string,
    tableNumber: string,
    sercingID: string
  ) {
    this.id = id;
    this.orderId = orderId;
    this.receiptNumber = receiptNumber;
    this.products = products;
    this.payments = payments;
    this.customer = customer;
    this.posNumber = posNumber;
    this.operator = operator;
    this.receiptInfo = receiptInfo;
    this.shop = shop;
    this.receiptTotal = receiptTotal;
    this.posParameters = posParameters;
    this.serviceID = serviceID;
    this.email = email;
    this.state = state;
    this.deliveryMethod = deliveryMethod;
    this.pickUpTime = pickUpTime;
    this.orderState = orderState;
    this.registrationNumber = registrationNumber;
    this.tableNumber = tableNumber;
    this.servingID = sercingID;
  }

  static getTotalAmount = (cart: Cart): number => {
    let amount = 0;
    if (cart.products.length > 0) {
      amount = cart.products.map((x) => x.totalAmount).reduce((a, b) => a + b);
    }
    return amount;
  };

  getTotal = (): string => {
    let amount = 0;
    if (this.products.length > 0) {
      amount = this.products.map((x) => x.totalAmount).reduce((a, b) => a + b);
    }
    return formatMoney(amount);
  };

  getProductCount = (): number => {
    if (this.products.length > 0) {
      return this.products.map((x) => x.quantity).reduce((a, b) => a + b);
    }
    return 0;
  };


  private static isDeliveryMethodInUse = (cart: Cart, method: DeliveryMethodEnum) : boolean => {
    if (cart.posParameters){
      const prm = cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-${method.toLowerCase()}-inuse`);
      //console.log('isMethodInUse', method, cart.posParameters, prm);
      if (prm)return (prm.parameterValue === '1' || prm.parameterValue.toLowerCase() === 'true');
    }
    return false;
  };

  // private static setDebugData = (cart: Cart|null) => {
  //   console.log('setDebugData');
  //   if (cart == null) return;
  //   let prm: Parameter|undefined = undefined;
  //   prm = cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-frontcounter-inuse`);
  //   if (prm){
  //     console.log('setDebugData 1', prm, cart.posParameters);
  //     prm.parameterValue = '1';
  //     console.log('setDebugData 2', prm, cart.posParameters);
  //   }
  //   prm = cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-takeaway-inuse`);
  //   if (prm)prm.parameterValue = '1';
  //   prm = cart.posParameters.find(p => p.parameterCode.toLowerCase()===`basketdelivery-tableserving-inuse`);
  //   if (prm)prm.parameterValue = '1';
  // }

  public getChoicesForPlaceToEat = (): DeliveryMethodEnum[] => {
    return Cart.getChoicesForPlaceToEat(this);
  }
  public static getChoicesForPlaceToEat = (cart: Cart|null): DeliveryMethodEnum[] => {
    const result:DeliveryMethodEnum[] = [];
    if (cart == null) return result;
    //Cart.setDebugData(cart);
    if (Cart.isDeliveryMethodInUse(cart, DeliveryMethodEnum.FrontCounter))
      result.push(DeliveryMethodEnum.FrontCounter);
    if (Cart.isDeliveryMethodInUse(cart, DeliveryMethodEnum.Takeaway))
      result.push(DeliveryMethodEnum.Takeaway);
    if (result.length === 0)
      result.push(DeliveryMethodEnum.FrontCounter);
    return result;
  }

  public getChoicesForTableServicing = (): DeliveryMethodEnum[] => {
    return Cart.getChoicesForTableServicing(this);
  }
  public static getChoicesForTableServicing = (cart: Cart|null): DeliveryMethodEnum[] => {
    const result:DeliveryMethodEnum[] = [];
    if (cart == null) return result;
    //Cart.setDebugData(cart);
    if (Cart.isDeliveryMethodInUse(cart, DeliveryMethodEnum.FrontCounter))
      result.push(DeliveryMethodEnum.FrontCounter);
    if (Cart.isDeliveryMethodInUse(cart, DeliveryMethodEnum.TableServing))
      result.push(DeliveryMethodEnum.TableServing);
    if (result.length === 0)
      result.push(DeliveryMethodEnum.FrontCounter);
    return result;
  }
}
