import { ErrorInfo, FC, useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../../context/KioskContext';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import Product from '../../../../models/product';
import React from 'react';
import BasketProductList from '../../Controls/BasketProductList';
import { deleteProductRow, DeliveryMethodEnum, setDeliveryMethod } from '../../../../helpers/basketApi';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ComponentDialog from '../ComponentDialog';
import RemoveProductDialog from '../RemoveProductDialog';
import SalePathData from '../../../../models/SalePathData';
import Cart from '../../../../models/cart';

interface Props {
  visible: boolean;
  width?: number|null;
}

const BasketDialog: FC<Props> = ({visible, width=null}) => {

  const {state, dispatch} = useContext(KioskContext);
  // const { screenSize } = Config();

  const [ productToRemove, setProductToRemove ] = useState<Product|null>(null);
  const {t} = useTranslation();

  const isInvalid:boolean = SalePathData.getInvalidProducts(state.salePathData, state.cart).length > 0;

  const setMethod = async (method: DeliveryMethodEnum) => {
    if (state.cart){
      if (state.cart.deliveryMethod !== method){
        const cart = await setDeliveryMethod(state.cart.id, method);
        dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      }
    }
  };

  const proceedToPayment = () => {
    if (state.cart == null)return;
    let choices = Cart.getChoicesForPlaceToEat(state.cart);
    if (choices.length===0)return;
    if (choices.length>1){
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.placeToEat});
    } else {
      let method1st: DeliveryMethodEnum = choices[0];
      choices = Cart.getChoicesForTableServicing(state.cart);
      if (choices.length===0)return;
      if (choices.length>1){
        setMethod(method1st);
        dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.servicing});
      } else {
        let method2nd: DeliveryMethodEnum = choices[0];
        if (method1st === DeliveryMethodEnum.FrontCounter){
          if (method2nd === DeliveryMethodEnum.FrontCounter) setMethod(DeliveryMethodEnum.FrontCounter);
          if (method2nd === DeliveryMethodEnum.TableServing) setMethod(DeliveryMethodEnum.TableServing);
        } else if (method1st === DeliveryMethodEnum.Takeaway){
          if (method2nd === DeliveryMethodEnum.FrontCounter) setMethod(DeliveryMethodEnum.Takeaway);
          if (method2nd === DeliveryMethodEnum.TableServing) setMethod(DeliveryMethodEnum.TakeawayTableServing);
        } else return;
        dispatch({type: KioskActionsEnum.SET_STEP, step: method2nd === DeliveryMethodEnum.TableServing ? StepsEnum.tableNumber : StepsEnum.confirmOrder});
      }
    }
  }

  const onClose = (code: string) => {
    if (code === 'Continue') {
      proceedToPayment();
    } else {
      dispatch({type: KioskActionsEnum.HIDE_CART});
    }
  }

 const handleChange = (product: Product): void => {
  const salePath = state.salePathData.findBySalePathCode(product.salePathCode);
  dispatch({type: KioskActionsEnum.SELECT_NODE, salePath, product});
  onClose('Close');
}

const handleRemove = (product: Product) => {
  setProductToRemove(product);
}

const confirmRemove = async (remove: boolean) => {
  if (!remove){
    setProductToRemove(null);
    return;
  }
  if (productToRemove)
    removeProduct(productToRemove);
};

const removeProduct = async (product: Product) => {
  if (!product){
    setProductToRemove(null);
    return;
  }
  if (state.cart!=null){
    const cart = await deleteProductRow(state.cart.id, product.rowId);
    if (cart==null){
      dispatch({ type: KioskActionsEnum.RESET_CART });
      return;
    }
    dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    setProductToRemove(null);
    if (cart.products.length===0){
      dispatch({ type: KioskActionsEnum.SELECT_ROOT });
      onClose('Close');
    }
  } else {
    setProductToRemove(null);
  }
};

const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
  handleApplicationError(area, state.cart?.id, undefined, error);
}

return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('BasketDialog', error, info)}>
    <ComponentDialog visible={visible} onClose={(code:string)=> {onClose(code);}}
      actions={[
        { code: 'Close', caption: t('button.continue-order'), buttonType: 'Secondary'},
        { code: 'Continue', caption: t('button.proceed-to-payment'), buttonType: 'Primary', disabled: isInvalid}
      ]}
      title={t('your-order')}
      fullscreen={false}>
        <BasketProductList onModify={(i) => handleChange(i)} onRemove={(i) => handleRemove(i)}/>
    </ComponentDialog>
    <RemoveProductDialog visible={productToRemove!=null} product={productToRemove} onClose={(remove: boolean) => confirmRemove(remove)} />
  </ErrorBoundary>
  );
};

export default BasketDialog;
