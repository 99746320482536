import { getConfig } from './apiConfig';
import { handleApiError } from './loggerApi';

export interface PaymentStatus {
  isDone: boolean,
  isError: boolean,
  screenMessage: string,
  cartId: string
}

const apiUrl:string = getConfig().apiUrl;

export const startCartPayment = async (
  cartId: string,
): Promise<boolean> => {
  try {
    console.log('startCartPayment', cartId);
    const response = await fetch(`${apiUrl}/Payco/StartCartPayment/${cartId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      // console.log('startCartPayment', 'ok');
      return true;
    } else {
      // console.log('startCartPayment', 'response', response);
      handleApiError('startCartPayment', response, cartId);
      return false;
    }
  } catch (error) {
    //console.log('startCartPayment', 'error', error);
    handleApiError('startCartPayment', error, cartId);
    throw error;
  }
};

export const cancelCartPayment = async (
  cartId: string,
): Promise<boolean> => {
  try {
    const response = await fetch(`${apiUrl}/Payco/AbortCartPayment/${cartId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return true;
    } else {
      handleApiError('cancelCartPayment', response, cartId);
      return false;
    }
  } catch (error) {
    handleApiError('cancelCartPayment', error, cartId);
    throw error;
  }
};

export const getCartPaymentStatus = async (
  cartId: string,
): Promise<PaymentStatus> => {
  try {
    const response = await fetch(`${apiUrl}/Payco/CartPaymentStatus/${cartId}`, {
    });
    if (response.ok) {
      //console.log('GotStatus', response)
      return await response.json();
    } else {
      handleApiError('getCartPaymentStatus', response, cartId);
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('getCartPaymentStatus', error, cartId);
    throw error;
  }
};
