import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss'
import { useTranslation } from "react-i18next";
import Icon from '@mui/icons-material/LunchDiningOutlined';

interface Props {
}

const waitTime: number = 3;

const ClosedPage: FC<Props> = () => {
  const [counter, setCounter] = useState<number>(waitTime);
  const [language, setLanguage] = useState<string>('fi');
  const {i18n} = useTranslation();

  useEffect(() => {
    const to:NodeJS.Timeout | null = waitTime > 0 ?setTimeout(()=>{
      if(counter>0)setCounter((c)=>c-1);
      if (waitTime>0&&counter===0){
        setCounter(waitTime);
        switch(language){
          case 'en':{
            setLanguage('fi');
            break;
          }
          case 'se':{
            setLanguage('en');
            break;
          }
          default:{
            setLanguage('se');
            break;
          }
        }
      }
      },1000):null;
    return () => {
      if (to) clearTimeout(to);
    };
  },[counter, language]);

  const getText = () : any => {
    const text:string = i18n.getFixedT(language)('closed');
    return ( text.split('\n').map((t,i) => {return ( <div key={i} className={t.length>0?styles.textContent:styles.emptyContent}> {t} </div>) }));
  }

  return (
    <div className={styles.kioskClosedBackground}>
      <div className={styles.kioskClosed}>
        <div className={styles.icon}>
          <div className={styles.sleep1}>Z</div>
          <div className={styles.sleep2}>Z</div>
          <div className={styles.sleep3}>Z</div>
          <Icon/>
        </div>
        <div className={styles.content}>
          {getText()}
        </div>
      </div>
    </div>
  );
};


export default ClosedPage;
