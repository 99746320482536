import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import SalePath from '../../../../models/SalePath';
import { Typography } from '@mui/material';
import Config from '../../../../hooks/Config';

interface Props {
  node: SalePath
}

const ProductProperties: FC<Props> = ({node}) => {
  const {t} = useTranslation();
  const { screenSize } = Config();

  const buildAllergensText = () : string => {
    const items: string[] = [];
    node.allergens.forEach(a => {
      items.push(` ${t(`allergens.${a}_name`)}. `);
    })
    return items.join('');
  }

  const fontSize: number = screenSize.getWidth(30);
  const fontSizeBody: number = screenSize.getWidth(20);

  return (
    <div className={styles.productProperties}>
      {node.specialDiets.length>0 && 
      <>
        <div className={styles.title}>
          <Typography variant='h2' sx={{fontSize: fontSize}}>{t(`specialDiets.title`)}</Typography>
        </div>
        <div className={styles.specialDiets}>
          {node.specialDiets.map((name: string, i: number) => (
            <div key={i} className={styles.specialDiet}>
              <Typography variant='body2' sx={{fontSize: fontSizeBody, fontWeight: '600'}}>{t(`specialDiets.${name}_description`)}</Typography>
            </div>
          ))}
        </div>
      </>
      }
      {node.allergens.length>0 && 
      <>
        <div className={styles.title}>
          <Typography variant='h2' sx={{fontSize: fontSize}}>{t(`allergens.title`)}</Typography>
        </div>
        <div className={styles.allergens}>
          <Typography variant='body2' sx={{fontSize: fontSizeBody}}>{buildAllergensText()}</Typography>
        </div>
      </>
      }
    </div>
  );
};

export default ProductProperties;