export default function useQueryParams() {
    const searchParams:URLSearchParams = new URLSearchParams(window.location.search);
    const parseNumber = (value: string|null|undefined, defaultValue: number|null = null) : number|null => {
        if (!value)return defaultValue;
        try {
            return Number.parseInt(value);
        } catch (e) {
            return defaultValue;
        }
    }
    const shopCode: string|null = searchParams.get('shop');  
    const posNumber: number|null =  parseNumber(searchParams.get('pos'));  
 
    return {shopCode: shopCode, posNumber: posNumber};
}