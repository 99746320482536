import './App.scss';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { KioskContext } from '../context/KioskContext';
import Config from '../hooks/Config';
import Basket from './pages/Basket';
import useQueryParams from '../hooks/useQueryParams'
import ErrorBoundary from './components/ErrorBoundary';
import { handleApplicationError } from '../helpers/loggerApi';
import useWindowState from '../hooks/useWindowState';
import { localSettings } from '../helpers/localStorage';
import ILocalSettings from '../models/localSettings';
import Settings from './pages/Settings';
import useUpdate from '../hooks/useUpdate';

const App: FC = () => {
  const {state} = useContext(KioskContext);
  const { width: windowWidth, height: windowHeight } = useWindowState();
  const [ updateInitialized, setUpdateInitialized] = useState<boolean>(false);
  const { initializeUpdate } = useUpdate();
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  const {version, debugMode } = Config();

  const queryParams = useQueryParams();
  var navigateTo:string|null=null;

  useEffect((): void => {
    if (Boolean(JSON.parse(process.env.REACT_APP_DEBUG||'0'))){
      document.documentElement.setAttribute('debug-mode', 'enabled')
    }
  }, [])

  useEffect((): void => {
    if (!updateInitialized){
      setUpdateInitialized(true);
      initializeUpdate();
    }
  }, [initializeUpdate, updateInitialized]);

  useEffect((): void => {
    const settings: ILocalSettings = localSettings.getSettings();
    if (queryParams.shopCode){
      if (settings.shopCode !== queryParams.shopCode){
        localSettings.setSettings({...settings, shopCode:queryParams.shopCode});
      }
    }
    if (queryParams.posNumber){
      if (settings.posNumber !== queryParams.posNumber){
        localSettings.setSettings({...settings, posNumber:queryParams.posNumber});
      }
    }
  }, [queryParams.shopCode, queryParams.posNumber])
  
  useEffect((): void => {
    console.log('React', React.version);
  }, []);
  useEffect((): void => {
    console.log('Version', version);
  }, [version]);
  useEffect((): void => {
    if (debugMode){
      console.log('DEBUG ON');
    }
  }, [debugMode]);

  // useEffect((): void => {
  // }, [debugMode]);

  useEffect(()=>{
    const targetWidth = 1080;
    const targetHeight = 1920;
    const ratio = targetWidth / targetHeight;

    const width: number = windowWidth || targetWidth;
    const maxWidth: number = (width < targetWidth) ? width : targetWidth;
    const scale: number = width < targetWidth ? width / targetWidth : 1;
    const height: number = maxWidth / ratio;

    document.documentElement.setAttribute('targetwidth', Math.round(maxWidth).toString());
    document.documentElement.setAttribute('targetheight', Math.round(height).toString());
    document.documentElement.setAttribute('maxheight', Math.round(windowHeight||targetHeight).toString());
    document.documentElement.setAttribute('scalewidth', scale.toFixed(3));
    refresh(true);
  },[windowWidth, windowHeight]);
  
  const initSettings = state.settings?.shopCode==='';
  const onError = async (error:Error, info:React.ErrorInfo) => {
    handleApplicationError('Root', state.cart?.id, undefined, error);
  }

  return (
    <div className="App"
      style={{
        maxHeight: windowHeight||1920,
        maxWidth: windowWidth||1080,
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: '#000000'
        }}>
      <Router>
        <ErrorBoundary onError={onError}>
            <Routes>
              { navigateTo && <Route path={navigateTo} />}
              <Route path="/" element={initSettings ? <Settings/> : <Basket/>}/> 
              <Route path="/settings" element={<Settings/>}/>
              <Route path="*" element={<>404</>}/>
            </Routes>
          </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
