import EditItem from "./EditItem";
import EditItemNodeChild, { EditItemNodeChildType } from "./EditItemNodeChild";
import SalePath from "./SalePath";

export enum EditItemNodeType {
  Root = 'Root',
  Path = 'Path',
  Product = 'Product',
  Child = 'Child'
}

export default class EditItemNode {

  editItem : EditItem;
  editItemNodeType : EditItemNodeType;
  children : EditItemNodeChild[];
  
  constructor(editItem: EditItem) {
    this.editItem = editItem;
    this.editItemNodeType = EditItemNode.resolveEditItemNodeType(editItem);
    this.children = EditItemNode.resolveNodeChildren(this);
  }

  static resolveEditItemNodeType = (editItem: EditItem): EditItemNodeType => {
    return this.resolveSalePathNodeType(editItem.salePath);
  };
  static resolveSalePathNodeType = (salePath: SalePath): EditItemNodeType => {
    if (salePath.parent == null)return EditItemNodeType.Root;
    if (EditItemNode.isNullOrEmpty(salePath.productCode)){
      if (EditItemNode.isNullOrEmpty(salePath.parent.productCode)) return EditItemNodeType.Path;
    } else {
      if (EditItemNode.isNullOrEmpty(salePath.parent.productCode)) return EditItemNodeType.Product;
    }
    return EditItemNodeType.Child;
  };

  private static isNullOrEmpty = (value: string|null|undefined): boolean => {
    return (value?value:'').trim()==='';
  }
  private static resolveNodeChildren = (editItemNode: EditItemNode): EditItemNodeChild[] => {
    //if (editItemNode.editItemNodeType !== EditItemNodeType.Product)return [];
    const children: EditItemNodeChild[] = this.resolveChildren(editItemNode.editItem);
    return children;
  };
  private static resolveChildren = (editItem: EditItem): EditItemNodeChild[] => {
    const children: EditItemNodeChild[] = []

    const items:EditItem[] = EditItem.sort(editItem.children);
    if (items.length===0)return [];
    items.forEach((c:EditItem) => {
      const parentFreeUnits:number = c.salePath.parent?.chainFreeUnits || 0;
      const parentIsContent:boolean = c.salePath.parent?.isContent || false;

      if (c.salePath.hasChoices() && c.salePath.isProperty() === false){
        const selectMany:boolean = c.salePath.chainFreeUnits !==null ? c.salePath.chainFreeUnits > 0 : false;
        const choices:EditItem[] = EditItem.sort(c.children).filter(i => i.salePath.isProperty() && (i.salePath.isChainProductDefined || selectMany));
        if (choices.length!==0){
          if (c.salePath.isSelectOne()){
            children.push( new EditItemNodeChild(c, EditItemNodeChildType.SelectOne));
          }
          else {
            children.push( new EditItemNodeChild(c, EditItemNodeChildType.SelectMany));
          }
        }
      } else if (c.salePath.isProperty()){
        if (!parentIsContent && parentFreeUnits === 0){
          if (c.salePath.isContent) {
            children.push( new EditItemNodeChild(c, EditItemNodeChildType.SelectProperty));
          } else {
            children.push( new EditItemNodeChild(c, EditItemNodeChildType.AdditionalProperty));
          }
        }
      } else {
        EditItemNode.resolveChildren(c).forEach(r => children.push(r));
      }
    });
    return children;
  };

}
  