import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItemNode from '../../../../models/EditItemNode';
import { formatMoney } from '../../../../helpers/formatHelper';
import { Typography } from '@mui/material';
import Config from '../../../../hooks/Config';
import Container from '../Container';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import SetCount from '../SetCount';
import SalePath from '../../../../models/SalePath';

interface SalePathItemProps {
  node: EditItemNode;
  onUpdateCart?: () => void;
}

const ProductControls: FC<SalePathItemProps> = ({node, onUpdateCart}) => {
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);
  const { screenSize } = Config();
  const {t} = useTranslation();

  if (!node)return <></>;
  
  const setProductCount = (count: number) => {
    if (count<1)count=1;
    if (count>9)count=9;

    node.editItem.quantity = count;
    refresh(true);
  }

  const price : string = `${formatMoney(
    node.editItem.quantity * (node.editItem.product==null?(
      SalePath.cumulatePrice(node.editItem.salePath) + node.editItem.getForcedPrices()
  ):(
    node.editItem.getForcedPrices()
  ))
  )} €`
  
  
  const fontSize: number = screenSize.getFontSize('body1');
  const height: number = screenSize.getWidth(300);
  const radius: number = screenSize.getWidth(15);

  return (
    <Container className={styles.productControls} style={{height: height, borderRadius: radius}}>
      <div className={styles.count}>
        <Typography variant='body1' sx={{fontSize: fontSize}}>{t('count')}</Typography>
        <SetCount count={node.editItem.quantity} onChange={(count:number)=> setProductCount(count)} enabled={true} />
      </div>
      <div className={styles.basket}>
        <Button buttontype='Primary' onClick={onUpdateCart}>{node.editItem.product ? t('button.update-basket') : t('button.add-basket')} {price}</Button>
      </div>
    </Container>
  );
};

export default ProductControls;
