import { FC, useContext,  ReactNode } from 'react';
import styles from './styles.module.scss'
import Product from '../../../../models/product';
import { KioskContext } from '../../../../context/KioskContext';
import BasketProduct from '../BasketProduct';
import Cart from '../../../../models/cart';
import { Typography, useTheme } from '@mui/material';
import Config from '../../../../hooks/Config';
import { formatMoney } from '../../../../helpers/formatHelper';
import { useTranslation } from 'react-i18next';

export interface Props {
  cart?: Cart;
  onModify?: (product: Product) => void;
  onRemove?: (product: Product) => void;
}

const BasketProductList: FC<Props> = ({cart=undefined, onModify, onRemove}) => {
  const theme = useTheme();
  const {t} = useTranslation();
  const { screenSize } = Config();
  const { state } = useContext(KioskContext);

  const source: Cart|null = cart===undefined?state.cart : cart;

  const doModify = (product: Product) => {
    if (onModify)onModify(product);
  }
  const doRemove = (product: Product) => {
    if (onRemove)onRemove(product);
  }

  const renderItem = (): ReactNode[] | undefined => {
    if (source?.products.length===0)return;
    
    return source?.products.map((i: Product, idx: number) => {
      return (
        <div key={idx}>
          <div className={styles.item}>
            <BasketProduct key={idx} product={i} onModify={onModify?doModify:undefined} onRemove={onRemove?doRemove:undefined} />
          </div>
        </div>
      );
    });
  }

  return (
    <div className={styles.container}>
      {renderItem()}
      <div className={styles.total} style={{borderTopColor: theme.palette.text.primary}}>
        <Typography fontSize={screenSize.getFontSize('button')} fontWeight={700}>{t('total')}</Typography>
        <Typography fontSize={screenSize.getFontSize('button')} fontWeight={700}>{`${formatMoney(state.cart?.receiptTotal||0)} €`}</Typography>
      </div>
    </div>
  );
};

export default BasketProductList;
