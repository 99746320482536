import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
  useTheme,
} from '@mui/material'
import styles from './styles.module.scss';
import Config, { FontSize } from '../../../../hooks/Config';
import { useEffect, useRef, useState } from 'react';
import ArcPercentage from '../../../../assets/icons/ArcPercentage';

// export enum ButtonTypes {
//   Primary,
//   Secondary,
//   Tertiary,
//   Text
// }

export type ButtonType = 'Primary'|'Secondary'|'Tertiary'|'Text';

interface ButtonProps extends MUIButtonProps {
  buttontype?: ButtonType,
  fontSize?: FontSize,
  timer?: number,
}

export default function Button(props: ButtonProps) {

  const theme = useTheme();
  const { screenSize } = Config();
  const [counter, setCounter] = useState<number>(props.timer||0);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const isTimed: boolean = props.timer !== undefined && props.timer > 0;

  useEffect(() => {
    if (props.timer && props.timer>0){
      setCounter(props.timer);
    }
  },[props.timer]);

  useEffect(() => {
    if (!isTimed)return;
    const to:NodeJS.Timeout | null = (counter > 0 ?setTimeout(()=>{
      if(counter>=0)setCounter((c)=>c-1);
      if (counter===1){
        if (buttonRef)
          buttonRef.current?.click();
      }
      },1000):null);
    return () => {
      if (to) clearTimeout(to);
    };
  },[counter, isTimed, props.timer]);


  const decideColor = () : any => {
    if (props.color)return props.color;
    switch(props.buttontype) {
      case 'Secondary': return 'primary';
      case 'Tertiary': return 'tertiary';
      case 'Text': return 'secondary';
      default: return 'primary';
    };
  }

  const decideTextColor = () : any => {
    if (props.color)return props.color;
    switch(props.buttontype) {
      case 'Secondary': return theme.palette.primary.main;
      case 'Tertiary': return theme.palette.text.primary;
      case 'Text': return theme.palette.primary.main;
      default: return theme.palette.background.default;
    };
  }

  const decideVariant = () : any => {
    if (props.variant)return props.variant;
    switch(props.buttontype) {
      case 'Secondary': return 'outlined';
      case 'Tertiary': return 'contained';
      case 'Text': return 'text';
      default: return 'contained';
    };
  }

  const showTimer: boolean = isTimed&&counter>=0;
  const timerPercentage:number = Math.abs(100 - Math.round((!isTimed || counter<=0) ? 100 : (counter / (props.timer||counter))*100));

  const arcRadius: number = screenSize.getWidth(30);
  const arcTop: number = screenSize.getWidth(8);
  const arcPadding: number = screenSize.getWidth(20);

  const height: number = screenSize.getWidth(props.fontSize === 'buttonSmall' ? 40 : props.fontSize === 'buttonLarge' ? 100 : 80);
  const padding: number = screenSize.getWidth(5);
  const radius: number = screenSize.getWidth(2);

  const color: any = decideColor();
  const variant: any = decideVariant();

  return (
    <MUIButton className={styles.btn} ref={buttonRef} color={color} variant={variant} {...props}
      sx={{
        color: decideTextColor(),
        height: height,
        minHeight: height,
        minWidth: height,
        fontSize: screenSize.getFontSize(props.fontSize || 'button'),
        paddingLeft: padding,
        paddingRight: padding,
        borderRadius: radius
      }}>
        <div className={styles.btnContent} style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'nowrap',
          justifyContent: 'center'
        }}>
          {props.children}
          {showTimer && (
            <div className={styles.arc} style={{paddingLeft: arcPadding, position:'relative',  top: arcTop}}>
              <ArcPercentage percentage={timerPercentage} value={counter} radius={arcRadius} thikness={0.5} color={decideTextColor()} />
            </div>
          )}
        </div>
    </MUIButton>
  )
}
