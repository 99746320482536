import { ErrorInfo, FC, useContext, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ContainerButton from '../../Controls/ContainerButton';
import Config from '../../../../hooks/Config';
import { DeliveryMethodEnum, setDeliveryMethod } from '../../../../helpers/basketApi';
import Cart from '../../../../models/cart';

interface Props {
}

const PlaceToEat: FC<Props> = () => {

  const { screenSize } = Config();
  const {state, dispatch} = useContext(KioskContext);
  const {t} = useTranslation();
  const [choices] = useState<DeliveryMethodEnum[]>(Cart.getChoicesForPlaceToEat(state.cart))

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const setMethod = async (method: DeliveryMethodEnum) => {
    if (state.cart){
      if (state.cart.deliveryMethod !== method){
        const cart = await setDeliveryMethod(state.cart.id, method);
        dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      }
    }
  };

  const proceed = (selectedPlace: DeliveryMethodEnum) => {
    let method: DeliveryMethodEnum = selectedPlace;
    if (state.cart == null)return;
    const choices = Cart.getChoicesForTableServicing(state.cart);
    if (choices.length===0)return;
    if (choices.length>1){
      setMethod(method);
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.servicing});
    } else {
      if (method === DeliveryMethodEnum.FrontCounter){
        method = choices[0] === DeliveryMethodEnum.FrontCounter ? DeliveryMethodEnum.FrontCounter : DeliveryMethodEnum.TableServing;
      } else if (method === DeliveryMethodEnum.Takeaway){
        method = choices[0] === DeliveryMethodEnum.FrontCounter ? DeliveryMethodEnum.Takeaway : DeliveryMethodEnum.TakeawayTableServing;
      }
      setMethod(method);
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.confirmOrder});
    }
  }

  const allowOnPremise = choices.find(c=>c===DeliveryMethodEnum.FrontCounter);
  const allowTakeaway = choices.find(c=>c===DeliveryMethodEnum.Takeaway);

  const buttonWidth: number = screenSize.getWidth(800);
  const buttonHeight: number = screenSize.getWidth(150);
  const buttonFontSize: number = screenSize.getFontSize('h1');
  const buttonGap: number = screenSize.getWidth(70);
  
  return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('PlaceToEat', error, info)}>
    <div className={styles.placeToEat} style={{}}>
      <div className={styles.buttons} style={{gap: buttonGap}}>
        {allowOnPremise &&
          <ContainerButton onClick={() => proceed(DeliveryMethodEnum.FrontCounter)}>
            <div className={styles.btn} style={{width: buttonWidth, height: buttonHeight}}>
              <Typography variant='h2' fontSize={buttonFontSize}>{t('button.inside')}</Typography>
            </div>
        </ContainerButton>}
        {allowTakeaway &&
          <ContainerButton onClick={() => proceed(DeliveryMethodEnum.Takeaway)}>
            <div className={styles.btn} style={{width: buttonWidth, height: buttonHeight}}>
              <Typography variant='h2' fontSize={buttonFontSize}>{t('button.away')}</Typography>
            </div>
        </ContainerButton>}
      </div>
    </div>
  </ErrorBoundary>
  );
};

export default PlaceToEat;
