import { ErrorInfo, FC, ReactNode, useContext } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import styles from './styles.module.scss';
import { KioskActionsEnum, StepsEnum } from '../../../context/KioskContext/types';
import Paid from '../Steps/Paid';
import { handleApplicationError } from '../../../helpers/loggerApi';
import ErrorBoundary from '../ErrorBoundary';
import SalePathList from '../Controls/SalePathList';
import SalePath from '../../../models/SalePath';
import Product from '../Controls/Product';
import { getCompanyConfig } from '../../../companyConfig/companyConfig';
import { Typography } from '@mui/material';
import { t } from 'i18next';
import Config from '../../../hooks/Config';
import PlaceToEat from '../Steps/PlaceToEat';
import Servicing from '../Steps/Servicing';
import TableNumber from '../Steps/TableNumber';
import ConfirmOrder from '../Steps/ConfirmOrder';
import Payment from '../Steps/Payment';

interface Props {
}

const MainContent: FC<Props> = () => {
  const {state, dispatch} = useContext(KioskContext);
  const { screenSize } = Config();


  const rootFontSize: number = screenSize.getWidth(50);

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const nodeClicked = (node: SalePath) => {
    dispatch({type: KioskActionsEnum.SELECT_NODE, salePath: node});
  }

  const content = () : ReactNode => {

    switch (state.step) {
      case StepsEnum.placeToEat: {
        return <PlaceToEat />
      }
      case StepsEnum.servicing: {
        return <Servicing />
      }
      case StepsEnum.tableNumber: {
        return <TableNumber />
      }
      case StepsEnum.confirmOrder: {
        return <ConfirmOrder />
      }
      case StepsEnum.payment: {
        return <Payment />
      }
      case StepsEnum.paid: {
        return <Paid />
      }
    }    

    const isProduct: boolean = (state.selectedNode != null && state.salePath != null && state.salePath.productID != null);

    return (state.salePath == null) ? (
      <div className={styles.concepts}>
        <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Concepts', error, info)}>
          {(state.cart?.products.length||0)===0 && <Typography variant='h1' sx={{fontSize: rootFontSize}}>{t('select-food')}</Typography>}
          <div className={styles.conceptsList}>
            <SalePathList columnCount={Number.parseInt(getCompanyConfig('UI.Root.Columns','2'))} onClick={(node: SalePath) => nodeClicked(node)}/>
          </div>
        </ErrorBoundary>
      </div>
    ) : (
      (isProduct) ? (
        <div className={styles.item}>
        <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Product', error, info)}>
          <Product node={state.selectedNode}/>
        </ErrorBoundary>
      </div>
      ) : (
        <div className={styles.items}>
        <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathItem', error, info)}>
          <div className={styles.itemList}>
            <SalePathList parent={state.salePath} columnCount={Number.parseInt(getCompanyConfig('UI.Browse.Columns','3'))} onClick={(node: SalePath) => nodeClicked(node)}/>
          </div>
        </ErrorBoundary>
      </div>
      )
    );
  }

  return content();
};

export default MainContent;

