import {
  Button,
  PaperProps,
} from '@mui/material'
import Container from '../Container';

interface Props extends PaperProps {
}

export default function ContainerButton(props: Props) {

  return (
    <Button sx={props.sx} style={props.style}>
      <Container {...props}>    
        {props.children}
      </Container>
    </Button>
  )
}