import { FC, PropsWithChildren, ReactNode } from 'react';
import { Dialog, DialogContent, DialogTitle, Slide, Typography, useTheme } from '@mui/material';
import React from 'react';
import styles from './styles.module.scss';
import { TransitionProps } from '@mui/material/transitions';
import { DialogActions } from '@mui/material';
import Button, { ButtonType } from '../../Controls/Button';
import { useTranslation } from 'react-i18next';
import Config from '../../../../hooks/Config';
import { IconStore } from '../../../../icons/iconStorage';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface Action {
  code: string,
  caption: string,
  buttonType?: ButtonType,
  timer?: number,
  disabled?: boolean,
}

interface Props extends PropsWithChildren {
  visible: boolean;
  title?: string;
  fullscreen?: boolean;
  actions? : Action[];
  iconName?: string;
  onClose?: (code: string) => void;
}

const ComponentDialog: FC<Props> = ({visible, title='', fullscreen = true, actions = [{code:'Ready',caption:'', buttonType: 'Primary'}], children, iconName, onClose}) => {

  const { screenSize } = Config();
  const theme = useTheme();
  const {t} = useTranslation();

  const handleClose = (code: string): void => {
    if (onClose) onClose(code);
  };

  const margin: number = screenSize.getWidth(40);
  const width: number = screenSize.getWidth(950);
  const clearIconSize: number = screenSize.getWidth(50);
  const iconSize: number = screenSize.getWidth(100);
  const fontSize: number = screenSize.getFontSize(fullscreen ? 'h1' : 'h2' );
  const buttonGap: number = screenSize.getWidth(20);

  const buildTitle = ()  :ReactNode => {
    //if (title==='')return <></>;
    if (fullscreen){
      return (
        <DialogTitle>
          <div className={styles.titleFullscreen} style={{fontSize: fontSize}}>
            <Typography sx={{color: theme.palette.primary.main , fontSize: screenSize.getFontSize('h1'), fontWeight: 700}} style={{textAlign: 'center', marginTop: margin}}>{title}</Typography>
          </div>
        </DialogTitle>
      );
    } else {
      return (
        <DialogTitle>
          <div className={styles.titleDialog} style={{fontSize: fontSize}}>
            <div className={styles.icons}>
            <div className={styles.icon} style={{marginTop: margin}}>
              {iconName&&<IconStore iconName={iconName} size={iconSize}/>}
            </div>
            <div className={styles.close} onClick={()=>handleClose('Close')} style={{marginRight: margin, marginTop: margin}}>
              <IconStore iconName='Clear' size={clearIconSize}/>
                {/* <ClearIcon fontSizeAdjust={22} style={{marginRight: margin, marginTop: margin}}/> */}
              </div>
            </div>
            <div className={styles.title}>
              {title!==''&&<Typography sx={{fontSize: screenSize.getFontSize('h2'), fontWeight: 700}} style={{marginLeft: margin, marginRight: margin, marginTop: margin}}>{title}</Typography>}
              
            </div>
          </div>
        </DialogTitle>
      );
    }
  }

  const buildActions = ()  :ReactNode => {
    return (
    <div style={{width: '100%', display: 'flex', gap: buttonGap}}>
      {actions.map((a,i)=> 
        <Button key={i}
          buttontype={a.buttonType}
          timer={a.timer||0}
          disabled={a.disabled||false}
          onClick={() => handleClose(a.code)}>{a.caption===''?t(`button.${a.code.toLowerCase()}`):a.caption}</Button>
        )}
    </div>
    );
  }

  return (
    <Dialog className={styles.dialog} open={visible} onClose={() => handleClose('Close')} TransitionComponent={Transition} scroll='paper' fullScreen={fullscreen} sx={{width: fullscreen ? '100%' : width, maxHeight: fullscreen ? '100%' : '90%', top: '5%'}}>
      {buildTitle()}
      <DialogContent style={{margin: margin}}>
        {children}
      </DialogContent>
      <DialogActions style={{marginLeft: margin, marginRight: margin, marginBottom: margin, marginTop: 0}}>
        {buildActions()}
      </DialogActions>
    </Dialog>
  );
};

export default ComponentDialog;
