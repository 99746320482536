import { FC } from 'react';
import SalePath from '../../../../models/SalePath';
import styles from './styles.module.scss';
import { Typography, useTheme } from '@mui/material';
import Config from '../../../../hooks/Config';
import StandardImage from '../StandardImage';
import ContainerButton from '../ContainerButton';
import DoneIcon from '@mui/icons-material/Done';

interface SalePathItemProps {
  node: SalePath;
  isSelected?: boolean;
  onClick?: (node: SalePath) => void;
}

const SalePathItem: FC<SalePathItemProps> = ({node, isSelected=false, onClick}) => {

  const theme = useTheme();
  const { screenSize } = Config();

  const clickHandler = (): void => {
    if (onClick){
      onClick(node);
    }
  }

  const width: number = screenSize.getWidth(200);
  const fontSize: number = screenSize.getWidth(20);
  const iconSize: number = screenSize.getWidth(40);
  const iconRight: number = screenSize.getWidth(23);
  const iconTop: number = screenSize.getWidth(20);

  const imageSource: string = node.imagePath;
  const label: string = node.name;

  return (
    <ContainerButton className={styles.card} onClick={clickHandler} sx={{borderColor: isSelected ? theme.palette.primary.main : 'transparent'}}>
      <StandardImage source={imageSource} width={'80%'}/>
      {isSelected && (
        <div className={styles.selected} style={{right: iconRight, top: iconTop}}>
          <DoneIcon className={styles.icon} style={{width: iconSize, height: iconSize, borderRadius: '50%', backgroundColor: theme.palette.primary.main, fontSize: iconSize*0.8, color: theme.palette.background.default}}/>
        </div>
      )}
      <div className={styles.name}>
        <Typography variant='h2' sx={{fontSize: fontSize, maxWidth: width, textAlign: 'center', overflowWrap: 'break-word'}}>{label.toUpperCase()}</Typography>
      </div>
    </ContainerButton>
  );
};

export default SalePathItem;
