import { FC, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import styles from './styles.module.scss';
import BasketDialog from '../Dialogs/BasketDialog';
import Banner from '../Controls/Banner';
import { StepsEnum } from '../../../context/KioskContext/types';
import useWindowState from '../../../hooks/useWindowState';
import MainContent from '../MainContent';
import ConceptHeader from '../Controls/ConceptHeader';
import SideMenu from '../SideMenu';
import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Config from '../../../hooks/Config';
import { getCompanyConfigFlag } from '../../../companyConfig/companyConfig';
import QRCode from '../Controls/QRCode';
import { localSettings } from '../../../helpers/localStorage';

interface Props {
  returnedFront: boolean;
}

const Main: FC<Props> = ({returnedFront}) => {
  const { state } = useContext(KioskContext);
  const { width: windowWidth } = useWindowState();
  const {t} = useTranslation();
  const { screenSize } = Config();
  const theme = useTheme();
  const {i18n} = useTranslation();
  const [shopCode] = useState<string>(localSettings.getSettings().shopCode);
  const [mobileUrl] = useState<string>( getCompanyConfigFlag('UI.Mobile.Advert', false) ? `${(process.env.REACT_APP_MOBILE_URI || '')}?shop=${shopCode}` : '');

  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);
  useEffect(()=>{refresh(true)},[i18n.language]);
  useEffect(()=>{refresh(true);},[windowWidth]);
  useEffect(()=>{refresh(true);},[state.salePath]);
  useEffect(()=>{refresh(true);},[screenSize]);

  const [returnTimer, setReturnTimer] = useState<NodeJS.Timeout|null>(null);
  const [showContent, setShowContent] = useState<boolean>(true);

  useEffect(() => {
    const tick = () => {
      setShowContent(true);
      if (returnTimer) {
        clearTimeout(returnTimer);
        setReturnTimer(null);
      }
    }
    if (returnedFront){
      setShowContent(false);
      setReturnTimer(setTimeout(() => tick(),250));
    }
  },[returnTimer, returnedFront]);

  const isItemSelected: boolean = state.salePath != null;
  const isCartActive: boolean = state.salePath != null || (state.cart?.products.length||0)>0;

  const maxWidth: number = screenSize.maxWidth;
  const width: number = screenSize.getWidth();
  const height: number = screenSize.targetHeight;
  const fontBody:number = screenSize.getFontSize('subtitle1');
  const bannerHeight: number = screenSize.getWidth(20);
  const headerHeight: number = screenSize.getWidth(350);
  const bottomMargin: number = 0
  const contentHeight: number = height - bannerHeight - headerHeight;
  const columnWidth: number = screenSize.getWidth(110);

  const isRoot: boolean = !isItemSelected;
  const showMenu: boolean = isItemSelected || isCartActive;

  const contentWidth: number = isRoot ? width : width - columnWidth;

  const showCode: boolean = mobileUrl!=='' && state.step === StepsEnum.collect;
  const sizeCode:number = screenSize.getWidth(150);
  const marginCodeRight:number = screenSize.getWidth(45);
  const marginCodeBottom:number = screenSize.getWidth(80);

  return (
    <div className={styles.root} style={{
      maxWidth: maxWidth,
      backgroundImage: `url(${process.env.REACT_APP_BASKET_IMAGE_URI + `/BasketKiosk/Background-${theme.palette.mode}.png`})`,
      backgroundSize: 'cover'
      }}>
      <div className={styles.banner} style={{height:bannerHeight}}>
        <Banner/>
      </div>
      <div className={styles.conceptHeader} style={{height:headerHeight}}>
        <ConceptHeader node={state.salePath}/>
      </div>
      {showContent && (
        <>
        {(showMenu === true && state.step < StepsEnum.payment) ? (
        <div className={styles.columns}>
          <div className={styles.columnLeft} style={{width:columnWidth, minWidth:columnWidth}}>
            <div className={styles.menu}>
              <SideMenu/>
            </div>
          </div>
          <div className={styles.columnContent} style={{maxWidth: contentWidth, width: contentWidth}} >
            <div className={styles.content} style={{height:contentHeight, marginBottom:bottomMargin}}>
              <MainContent/>
            </div>
          </div>
          {(isRoot) && (<div className={styles.columnRight} style={{width:columnWidth}}></div>)}
        </div>
        ) : (
        <div>
          <div className={styles.columnContent}>
            <div className={styles.content} style={{height:contentHeight, marginBottom:bottomMargin}}>
              <div className={styles.contentWithCode} style={{height:contentHeight, marginBottom:bottomMargin}}>
              <MainContent/>
              {showCode && (
                  <div className={styles.code} style={{marginRight: marginCodeRight, marginBottom: marginCodeBottom}}>
                    <Typography variant="h2" fontSize={fontBody} style={{width: sizeCode}}>{t('mobile.advert')}</Typography>
                    <QRCode url={mobileUrl} size={sizeCode} />
                  </div>
              )}
              </div>
            </div>
          </div>
        </div>
        )}
        </>
      )}
      <BasketDialog visible={state.step === StepsEnum.cart}/>
    </div>      
  );
};

export default Main;

