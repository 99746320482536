import { FC, ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import EditItemNode from '../../../../models/EditItemNode';
import { Typography, useTheme } from '@mui/material';
import EditItemNodeChild, { EditItemNodeChildType } from '../../../../models/EditItemNodeChild';
import Config from '../../../../hooks/Config';
import Container from '../Container';
import StandardImage from '../StandardImage';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import ComponentDialog from '../../Dialogs/ComponentDialog';
import ProductSelectOne from '../ProductOption/ProductSelectOne';
import ProductSelectMany from '../ProductOption/ProductSelectMany';

interface SalePathItemProps {
  node: EditItemNodeChild;
}

const ProductAdditionalChoice: FC<SalePathItemProps> = ({node}) => {
  const [showSelectMany, setShowSelectMany] = useState<boolean>(false);
  const [showSelectOne, setShowSelectOne] = useState<boolean>(false);
  const { screenSize } = Config();
  const theme = useTheme();
  const {t} = useTranslation();

  if (!node)return <></>;

  const skip: boolean = node.childType === EditItemNodeChildType.AdditionalProperty && node.editItem.salePath.isOptional;
  
  const imageSource: string = node.editItem.salePath.imagePath;

  const editItemNode: EditItemNode = new EditItemNode(node.editItem);

  const minHeight: number = screenSize.getWidth(150);
  const imageHeight: number = screenSize.getWidth(130);
  const buttonHeight: number = screenSize.getWidth(40);
  const controlWidth: number = screenSize.getWidth(140);
  const titleFontSize: number = screenSize.getFontSize('body1');
  const fontSize: number = screenSize.getFontSize('body2');
  const margin: number = screenSize.getWidth(20);
  const marginTitle: number = screenSize.getWidth(20);
  const radius: number = screenSize.getWidth(15);
 
  const buttonStyle = {height: buttonHeight, minHeight: buttonHeight};

  const buildControl = () : ReactNode => {
    return (
    <div style={{maxHeight: buttonHeight, width: controlWidth}}>
      {node.childType===EditItemNodeChildType.SelectOne && <Button buttontype='Secondary' onClick={()=>setShowSelectOne(true)} fontSize='buttonSmall' style={buttonStyle}>{t('button.change')}</Button>}
      {node.childType===EditItemNodeChildType.SelectMany && <Button buttontype='Secondary' onClick={()=>setShowSelectMany(true)} fontSize='buttonSmall' style={buttonStyle}>{t('button.edit')}</Button>}
    </div>);
  }

  const selectedNode = node.childType === EditItemNodeChildType.SelectOne ? node.editItem.children.find(c=>c.quantity>0):undefined;
  const title: string = selectedNode ? selectedNode.name : node.editItem.name;

  return (
    <Container style={{minHeight: minHeight, borderRadius: radius, backgroundColor: skip ? theme.palette.action.disabledBackground : undefined}}>
      <div className={styles.parts} style={{paddingLeft: margin, paddingRight: margin, paddingTop: margin/2, paddingBottom: margin/2}}>
        <div className={styles.image}>
          <StandardImage source={imageSource} height={imageHeight}/>
        </div>
        <div className={styles.part} style={{marginLeft: margin}}>
          <div className={styles.title} style={{marginBottom: marginTitle}}>
            <Typography variant='body1' sx={{fontSize: titleFontSize, fontWeight: 600}}>{title}</Typography>
          </div>
          <div className={styles.subPart}>
            <div className={styles.control}>
              {buildControl()}
            </div>
            <div className={styles.description}>
              <Typography variant='body1' sx={{fontSize: fontSize}}>{node.editItem.salePath.description}</Typography>
            </div>
          </div>
        </div>
      </div>
      <ComponentDialog title={editItemNode.editItem.name} visible={showSelectOne} onClose={() => setShowSelectOne(false)} fullscreen={true}>
        <ProductSelectOne node={editItemNode} />
      </ComponentDialog>
      <ComponentDialog title={editItemNode.editItem.name} visible={showSelectMany} onClose={() => setShowSelectMany(false)} fullscreen={true}>
        <ProductSelectMany node={editItemNode} />
      </ComponentDialog>
    </Container>
  );
};

export default ProductAdditionalChoice;
