import { ErrorInfo, FC, useContext, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import Button from '../../Controls/Button';
import Config from '../../../../hooks/Config';
import NumericEntry from '../../Controls/NumericEntry';
import { setServingId } from '../../../../helpers/basketApi';

interface Props {
}

const TableNumber: FC<Props> = () => {

  const { screenSize } = Config();
  const {state, dispatch} = useContext(KioskContext);
  const [tableNumber, setTableNumber] = useState<number|undefined>(undefined);
  const {t} = useTranslation();

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const allowToProceed: boolean = (tableNumber !== undefined && tableNumber>0);

  const proceedWithServicingId = async (servingId:string) => {
    if (state.cart){
      if (state.cart.servingID !== servingId){
        const cart = await setServingId(state.cart.id, servingId);
        dispatch({type: KioskActionsEnum.UPDATE_CART, cart});
      }
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.confirmOrder});
    }
  }

  const confirm = () => {
    if (allowToProceed&&tableNumber){
      proceedWithServicingId(tableNumber.toString());
    }    
  }

  const marginTop: number = screenSize.getWidth(150);
  const numpadWidth: number = screenSize.getWidth(650);

  const width: number = screenSize.getWidth(900);
  const leftMargin: number = screenSize.getWidth(20);
  const marginBottom: number = screenSize.getWidth(80);
  
  return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('TableNumber', error, info)}>
    <div className={styles.tableNumber} style={{width: width, marginLeft: leftMargin, paddingBottom: marginBottom}}>
    <div className={styles.numpad} style={{width: numpadWidth, marginTop: marginTop}}>
      <NumericEntry value={tableNumber} showValue={true} noValue={t('table-novalue')} onValueChange={(value: number|undefined) => setTableNumber(value)}></NumericEntry>
    </div>
    <div className={styles.continue} style={{}}>
      <Button disabled={!allowToProceed} onClick={confirm} style={{width: numpadWidth}}>{t('button.ready')}</Button>
    </div>
    </div>
  </ErrorBoundary>
  );
};

export default TableNumber;
