import { FC, ReactNode, useContext } from 'react';
import styles from './styles.module.scss';
import SalePath from '../../../../models/SalePath';
import { Typography } from '@mui/material';
import Config from '../../../../hooks/Config';
import { KioskContext } from '../../../../context/KioskContext';
import { useTranslation } from 'react-i18next';
import { StepsEnum } from '../../../../context/KioskContext/types';

interface Props {
  node?: SalePath|null;
  title?: string;
}

const ConceptHeader: FC<Props> = ({node=null, title=undefined}) => {
  const {state} = useContext(KioskContext);
  const {t} = useTranslation();
  const { screenSize } = Config();
  
  const rootNode: SalePath|null = node?.getRoot()||null;

  const getImageSource = () : string => {
    if (state.salePath==null)return'';
    if (state.step > StepsEnum.cart)return '';
    return rootNode?.imagePath||'';
  }
  const imageSource: string = getImageSource();

  const imageHeight: number = screenSize.getWidth(200);
  const fontSize: number = screenSize.getWidth(50);
  const marginBottom: number = screenSize.getWidth(10);

  const productsRegistered: boolean = (state.cart?.products.length||0)>0;

  const getCaption = () : string => {
    switch (state.step) {
      case StepsEnum.placeToEat: {return t('where-do-eat');}
      case StepsEnum.servicing: {return t('delivery-method');}
      case StepsEnum.tableNumber: {return t('table-servicing');}
      case StepsEnum.confirmOrder: {return t('confirm-order');}
      case StepsEnum.payment: {return t('button.pay-the-order');}
      case StepsEnum.paid: {return t('thank-for-order');}
    }
    return (state.salePath == null && productsRegistered) ? t('continue') :  title||node?.name||'';
  }

  const buildHeader = () : ReactNode => {
      return (
        <div className={styles.conceptHeader} style={{paddingBottom: marginBottom}}>
          <div className={styles.image} style={{maxHeight: imageHeight}} >
          {imageSource!=='' &&
            <img
              src={imageSource}
              alt=""
              id={rootNode?.id.toString()}
            />
          }
          </div>      
          <Typography variant='h1' sx={{fontSize: fontSize}}>{getCaption()}</Typography>
        </div>
      );
    //}
  }

  return buildHeader();
};

export default ConceptHeader;

