import { FC, useRef } from 'react';
import styles from './styles.module.scss';
import { alpha, Typography, useTheme } from '@mui/material';

interface Props {
}

const Banner: FC<Props> = () => {
  const theme = useTheme();
  const bannerRef = useRef<HTMLDivElement>(null);

  const caption = process.env.REACT_APP_COPYRIGHT || 'PosManager | CGI'

  const fontSize: number = (bannerRef.current?.clientHeight||0)*0.8;

  return (
    <div ref={bannerRef} className={styles.banner} style={{backgroundColor: alpha(theme.palette.text.primary, 0.2)}}>
      <Typography variant="h2" fontSize={fontSize} style={{color: theme.palette.text.primary}}>{caption}</Typography>
    </div>
  )
};


export default Banner;
