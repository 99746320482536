import { FC, ReactElement, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import EditItemNode from '../../../../models/EditItemNode';
import { formatMoney } from '../../../../helpers/formatHelper';
import ProductProperties from '../ProductProperties';
import { Slide, Typography, useTheme } from '@mui/material';
import EditItemNodeChild, { EditItemNodeChildType } from '../../../../models/EditItemNodeChild';
import ProductAdditionalChoice from '../ProductAdditionalChoice';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import Container from '../Container';
import Config from '../../../../hooks/Config';
import StandardImage from '../StandardImage';
import ProductControls from '../ProductControls';
import ComponentDialog from '../../Dialogs/ComponentDialog';
import ProductSelectMany from '../ProductOption/ProductSelectMany';
import { KioskContext } from '../../../../context/KioskContext';
import ProductData from '../../../../models/productData';
import { addProductRow, deleteProductRow, initializeCart } from '../../../../helpers/basketApi';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import Cart from '../../../../models/cart';
import { getCompanyConfigFlag } from '../../../../companyConfig/companyConfig';

interface SalePathItemProps {
  node: EditItemNode|null;
}

const Product: FC<SalePathItemProps> = ({node}) => {
  const {state, dispatch} = useContext(KioskContext);
  const theme = useTheme();
  const { screenSize } = Config();
  const {t} = useTranslation();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [propertyDialogOpen, setPropertyDialogOpen] = useState<boolean>(false);

  useEffect(()=>{if (!isVisible){setIsVisible(true)}},[isVisible]);

  if (!node)return <></>;
  
  
  const imageSource: string = node.editItem.salePath.imagePath;

  const width: number = screenSize.getWidth(900);
  const leftMargin: number = screenSize.getWidth(20);

  const priceWidth: number = screenSize.getWidth(250);
  const priceHeight: number = screenSize.getWidth(60);
  const priceLeft: number = screenSize.getWidth(250);
  const priceBottom: number = screenSize.getWidth(100);
  const fontSize: number = screenSize.getWidth(25);
  const editWidth: number = width * 0.4;
  const partHeight: number = width * 0.12;
  const partMargin: number = screenSize.getWidth(15);
  const radius: number = screenSize.getWidth(15);
  
  const hasProperties: boolean = node.children.find(c=>c.childType===EditItemNodeChildType.SelectProperty) !== undefined;

  const buildAdditionalChoices = () : ReactElement => {
    const combine: boolean = getCompanyConfigFlag('UI.Product.CombineProperties');

    const choices = node.children.flatMap((child: EditItemNodeChild, i: number) => {
      if (child.childType === EditItemNodeChildType.SelectProperty)return null;
      // if (combine && child.childType === EditItemNodeChildType.SelectMany){
      //   console.log('SKIP', child.editItem.name, child.childType, child.editItem.salePath);
      // }
      if (combine && child.childType === EditItemNodeChildType.SelectMany && child.editItem.salePath.isContent)return null;
      return (
        <div key={i} className={styles.part} style={{minHeight: partHeight, marginTop: partMargin}}>
          <ProductAdditionalChoice node={child}/>
        </div>
      );
    });
    return <>{choices}</>;
  }

  const editProperties = () => {
    setPropertyDialogOpen(true);
  }

  const addProductToBasket = async () => {
      var cart: Cart | null = state.cart;
      const updatedProductData:ProductData = node.editItem.generateProductData();
      if (node.editItem.product?.rowId){
        if (cart!=null){
          cart = await deleteProductRow(cart.id, node.editItem.product?.rowId);
          if (cart==null){
            dispatch({ type: KioskActionsEnum.RESET_CART });
            return;
          }
          cart = await addProductRow(cart.id, updatedProductData);
        }
      } else {
        if (state.cart==null || state.cart.state.toLowerCase() === 'finalized'){
          cart = await initializeCart();
        }
        if (cart!=null){
          cart = await addProductRow(cart.id, updatedProductData);
          if (cart==null){
            dispatch({ type: KioskActionsEnum.RESET_CART });
            return;
          }
        }
      }
      node.editItem.reset();
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      dispatch({type: KioskActionsEnum.SELECT_PARENT});
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.cart});
  }

  return (
    <Slide in={isVisible} direction='up'>
    <div className={styles.productParts} style={{width: width, marginLeft: leftMargin}}>

      <Container className={styles.product}
          style={{
            fontSize: fontSize,
            paddingBottom: leftMargin,
            borderRadius: radius
          }}>
            {/* Kuvan koko 540 x 309 */}
            <StandardImage source={imageSource} width={'75%'}>
            <div className={styles.price}
              style={{
                color: theme.palette.background.default,
                backgroundColor: theme.palette.secondary.main,
                position: 'relative',
                width: priceWidth,
                height: priceHeight,
                borderRadius: priceHeight,
                left: priceLeft,
                bottom: priceBottom,
                alignContent: 'center'
              }}
              >
              <span>{formatMoney(node.editItem.salePath.salePrice)} €</span>
            </div>
            </StandardImage>

          <div className={styles.productDescription}>
            <Typography variant='body1' sx={{fontSize: fontSize}} >{node.editItem.salePath.description}</Typography>
          </div>
          <div className={styles.productProperties}>
            <ProductProperties node={node.editItem.salePath} />
          </div>
          {hasProperties && (
            <div className={styles.productEdit} style={{width: editWidth}}>
              <Button buttontype='Secondary' onClick={editProperties}>{t('button.edit')}</Button>
            </div>
          )}
      </Container>
      {buildAdditionalChoices()}
      <div className={styles.footer} style={{marginTop: partMargin, marginBottom: partMargin}}>
        <ProductControls node={node} onUpdateCart={addProductToBasket}/>
      </div>
      <ComponentDialog title={node.editItem.name} visible={propertyDialogOpen} onClose={() => setPropertyDialogOpen(false)} fullscreen={true}>
        <ProductSelectMany node={node} source='Dialog' />
      </ComponentDialog>
    </div>
    </Slide>
  );
};

export default Product;
