import { FC } from 'react';
import styles from './styles.module.scss'
import Config from '../../../../hooks/Config';

export interface Props {
  count:number;
}

const InactivityCounter: FC<Props> = ({count}) => {
  const { screenSize } = Config();

  const fontSize = screenSize.getFontSize('body1') * 0.8;
  return (
    <div className={styles.inactivityCounter} style={{fontSize: fontSize}}>
      {count}
    </div>
  );
};

export default InactivityCounter;
