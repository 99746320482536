import { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import useWindowState from '../../../../hooks/useWindowState';
import Config from '../../../../hooks/Config';
import { useTheme } from '@mui/material';

interface Props {
}

const InitializingPage: FC<Props> = () => {
  const { height: windowHeight } = useWindowState();
  const theme = useTheme();
  const { screenSize } = Config();

  useEffect(()=>{
    function backgroundImageVisible(visible: boolean) {
      let element = document.getElementById('bg');
      if (element)
        element.style.opacity = visible ? '1' : '0';
    }
  
    backgroundImageVisible(true);
  },[]);

  const maxWidth: number = screenSize.maxWidth;
  const height: number = windowHeight||1920;

  const imageUrl:string = `${process.env.REACT_APP_BASKET_IMAGE_URI + `/BasketKiosk/Background-${theme.palette.mode}.png`}`

  return (
    <div className={styles.root} style={{
      maxWidth: maxWidth,
      height: height,
      backgroundColor: theme.palette.background.default,
      }}>
      <div id='bg' className={styles.content} style={{opacity: 0, transition: 'opacity 3s'}}>
        <img src={imageUrl} alt='' />
      </div>
    </div>      
  );
};

export default InitializingPage;
