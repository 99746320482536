import { FC, useContext, useState } from 'react';
import styles from './styles.module.scss';
import { KioskContext } from '../../../context/KioskContext';
import Config from '../../../hooks/Config';
import { KioskActionsEnum } from '../../../context/KioskContext/types';
import { useTranslation } from 'react-i18next';
import ILocalSettings from '../../../models/localSettings';
import { localSettings } from '../../../helpers/localStorage';
import { useNavigate } from 'react-router-dom';
import { Button, FormControl, Paper, TextField } from '@mui/material';

const Settings: FC<{}> = () => {

  const {state, dispatch} = useContext(KioskContext);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const { screenSize } = Config();
  const [settings] = useState<ILocalSettings>(state.settings||localSettings.getSettings());

  const [shopCode, setShopCode] = useState<string>(settings.shopCode);
  const [posNumber, setPosNumber] = useState<number>(settings.posNumber);
  const [timerFront, setTimerFront] = useState<number>(settings.timerFront);
  const [timerButton, setTimerButton] = useState<number>(settings.timerButton);
  const [timerPaidButton, setTimerPaidButton] = useState<number>(settings.timerPaidButton);
  const [timerInactivity, setTimerInactivity] = useState<number>(settings.timerInactivity);
  const [timerReload, setTimerReload] = useState<number>(settings.timerReload);


  const handleSubmit = (event: any) => {
    event.preventDefault();
    const newSettings : ILocalSettings = {
      shopCode: shopCode,
      posNumber: posNumber,
      timerFront: timerFront,
      timerButton: timerButton,
      timerPaidButton: timerPaidButton,
      timerInactivity: timerInactivity,
      timerReload: timerReload,
    };
    dispatch({type: KioskActionsEnum.SET_SETTINGS, settings: newSettings});
    navigate('/');
  }

  const parseNumber = (value: string, defaultValue?: number) : number => {
    const numb:number = Number.parseInt(value);
    return validateNumber(numb, defaultValue);
  }
  const validateNumber= (value: number, defaultValue?: number) : number => {
    if (isNaN(value)){
      return defaultValue===undefined ? 0 : defaultValue;
    }
    return value;
  }

  const width: number = screenSize.getWidth(700);
  const marginPaper: number = screenSize.getWidth(50);
  const marginField: number = screenSize.getWidth(10);
  const fontSize: number = screenSize.getFontSize('h2');

  return (
<div className={styles.settings}>
        <h2>{t('settings')}</h2>

        <form onSubmit={handleSubmit}  className={styles.form} style={{width: width}}>
        <FormControl className={styles.controls} style={{
          fontSize: fontSize
          }}>
        <Paper className={styles.paper} style={{margin: marginPaper}}> 
            <div style={{margin: marginField}}>
              <TextField className='field' name='shopCode' type="text" variant='standard' label={t('shop-code')} fullWidth={true}
                value={shopCode} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setShopCode(event.target.value);}}
              />
            </div>
            <div style={{margin: marginField}}>
              <TextField className='field' name='posCode' type="text" variant='standard' label={t('pos-code')} fullWidth={true}
                value={posNumber} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPosNumber(parseNumber(event.target.value));}}
                // inputProps={{style: fieldStyle()}} InputLabelProps={{style: labelStyle()}}
                />
            </div>
          </Paper>
          <Paper className={styles.paper} style={{margin: marginPaper}}> 
            <div style={{margin: marginField}}>
              <TextField className='field' name='timerFront' type="text" variant='standard' label={t('timer-front')} fullWidth={true}
                value={timerFront} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerFront(parseNumber(event.target.value, 0));}}
                />
            </div>
            <div style={{margin: marginField}}>
              <TextField className='field' name='timerButton' type="text" variant='standard' label={t('timer-button')} fullWidth={true}
                value={timerButton} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerButton(parseNumber(event.target.value, 0));}}
                />
            </div>
            <div style={{margin: marginField}}>
              <TextField className='field' name='timerPaidButton' type="text" variant='standard' label={t('timer-paidbutton')} fullWidth={true}
                value={timerPaidButton} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerPaidButton(parseNumber(event.target.value, 0));}}
                />
            </div>
            <div style={{margin: marginField}}>
              <TextField className='field' name='timerInactivity' type="text" variant='standard' label={t('timer-inactivity')} fullWidth={true}
                value={timerInactivity} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerInactivity(parseNumber(event.target.value, 0));}}
                />
            </div>
            <div style={{margin: marginField}}>
              <TextField className='field' name='timerReload' type="text" variant='standard' label={t('timer-reload')} fullWidth={true}
                value={timerReload} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTimerReload(parseNumber(event.target.value, 0));}}
                />
            </div>
          </Paper>
          <div className={styles.footer}>
            <Button type='submit'>{t('button.confirm')}</Button>
          </div>
        </FormControl>
        </form>
      </div>
        );
};

export default Settings;
