import ILocalSettings from '../models/localSettings';
import SalePath from '../models/SalePath';
import { getConfig } from './apiConfig';
import { localSettings } from './localStorage';
import { handleApiError } from './loggerApi';

const apiUrl: string = getConfig().apiUrl;

export const getSalePaths = async (language?: string): Promise<Array<SalePath>> => {
  try {
    const settings: ILocalSettings = localSettings.getSettings();
    const response = await fetch(`${apiUrl}/search/${settings.shopCode}/salepath${language!==undefined?`?language=${language}`:''}`, {
    });
    if (response.ok) {
      return SalePath.convertAll(await response.json());
    } else {
      handleApiError('getSalePaths', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getSalePaths', error);
    throw error;
  }
};

export const getDocument = async (documentCode: string, language?: string): Promise<string> => {
  try {
    const settings: ILocalSettings = localSettings.getSettings();
    const response = await fetch(`${apiUrl}/search/${settings.shopCode}/document/${documentCode}${language!==undefined?`?language=${language}`:''}`, {
    });
    if (response.ok) {
      return await response.text();
    } else {
      handleApiError('getDocument', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getDocument', error);
    throw error;
  }
};
