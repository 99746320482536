import { ErrorInfo, FC, useContext } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../../context/KioskContext';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ComponentDialog from '../ComponentDialog';
import Config from '../../../../hooks/Config';

interface Props {
  visible: boolean;
  onClose?: () => void;
}

const ClearCartDialog: FC<Props> = ({visible, onClose}) => {

  const {state, dispatch} = useContext(KioskContext);
  const { screenSize } = Config();
  const {t} = useTranslation();

  const closing = (code: string) => {
    if (code === 'Clear'){
      dispatch({type: KioskActionsEnum.REMOVE_ORDER});
      dispatch({type: KioskActionsEnum.SELECT_ROOT});
      dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.collect});
    }

    if (onClose)onClose();
  }

const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
  handleApplicationError(area, state.cart?.id, undefined, error);
}

return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('ClearCartDialog', error, info)}>
    <ComponentDialog visible={visible} onClose={(code:string)=> {closing(code);}}
            actions={[
              { code: 'Cancel', caption: t('button.cancel'), buttonType: 'Secondary'},
              { code: 'Clear', caption: t('cancelOrder'), buttonType: 'Primary'},
            ]}
            title={t('cancelOrder')}
            iconName={'Alarm'}
            fullscreen={false}>
      <Typography fontSize={screenSize.getFontSize('h2')}>{t('cancelOrderContent')}</Typography>
    </ComponentDialog>
  </ErrorBoundary>
  );
};

export default ClearCartDialog;
