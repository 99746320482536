import SalePath from "./SalePath";
import Cart from "./cart";
import Product from "./product";

export default class SalePathData {

  rootItems: Array<SalePath>;

  constructor(
    rootItems: Array<SalePath>
    ) {
      this.rootItems = rootItems;
    }

  public findBySalePathCode = (salePathCode: string, item?: SalePath | null) : SalePath | null => {
    if (item != null){
      if (item.code === salePathCode){
        return item;
      }
      for (let i = 0; i < item.children.length; i++) {
        const result = this.findBySalePathCode(salePathCode, item.children[i])
        if (result){
          return result;
        }
      }
      return null;
    }
    else
    {
      for (let i = 0; i < this.rootItems.length; i++) {
        const result = this.findBySalePathCode(salePathCode, this.rootItems[i])
        if (result){
          return result;
        }
      }
      return null;
    }
  }


  public checkVisibility = () : boolean => {
    let changed:boolean = false;
    this.rootItems.forEach(d => {
      const wasVisible:boolean = d.isVisible;
      d.checkVisibility();
      if (!changed && wasVisible !== d.isVisible)
        changed = true;
    });
    return changed
  }
  
  public isClosed = () : boolean => {
    return (this.rootItems.find(r => r.isVisible) === undefined);
  }


  public getConcepts = () : boolean => {

    return (this.rootItems.find(r => r.isVisible) === undefined);
  }

  static getConcepts = (salePathData:SalePathData, cart: Cart) : SalePath[] => {
    const concepts:SalePath[] = [];
    cart.products.forEach((p)=>{
      const salePath:SalePath|null = salePathData.findBySalePathCode(p.salePathCode);
      if (salePath!=null){
        const root:SalePath = salePath.getRoot();
        if (concepts.find(i => i.code === root.code)===undefined){
          concepts.push(root);
        }
      }
    })
    return concepts;
  }

  static isProductInvalid = (salePathData:SalePathData, product: Product) : boolean => {
    const salePath:SalePath|null = salePathData.findBySalePathCode(product.salePathCode);
    if (salePath==null) return false;
    const root:SalePath = salePath.getRoot();
    if (root == null || root.openingHours == null) return false;
    return !root.openingHours.isOpen();
  }

  static getInvalidProducts = (salePathData:SalePathData, cart: Cart|null) : Product[] => {
    const invalidProducts:Product[] = [];
    if (cart==null) return invalidProducts;
    const source:Product[] = cart.products;
    for (let index = 0; index < source.length; index++) {
      const product = source[index];
      if (this.isProductInvalid(salePathData, product)){
        invalidProducts.push(product);
      }
    }
    return invalidProducts;
  }

  public getCurbsideItem = () : SalePath|undefined => {
    return SalePathData.getCurbsideItem(this);
  }
  static getCurbsideItem = (salePathData:SalePathData) : SalePath|undefined => {
    return salePathData.rootItems.find(r => r.nodeType.toLowerCase()==='curbside')
  }

}
