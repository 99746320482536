import { FC, ReactNode, useState } from 'react';
import styles from './styles.module.scss';
import EditItem from '../../../../../models/EditItem';
import EditItemNode from '../../../../../models/EditItemNode';
import Config from '../../../../../hooks/Config';
import { getCompanyConfigFlag } from '../../../../../companyConfig/companyConfig';
import EditItemNodeChild, { EditItemNodeChildType } from '../../../../../models/EditItemNodeChild';
import { Typography } from '@mui/material';
import ProductSelectManyChoices from '../ProductSelectManyChoices';

type source = 'Dialog'|'Component';

interface Props {
  node: EditItemNode;
  source?: source;
}

const ProductSelectMany: FC<Props> = ({node, source = 'Component'}) => {
  const { screenSize } = Config();
  const [ combine ] = useState<boolean>(getCompanyConfigFlag('UI.Product.CombineProperties'));

  const marginH: number = screenSize.getWidth(20);

  const resolveNodes = () : EditItem[] => {
    const acceptItem = (item: EditItemNodeChild) : boolean => {
      if (source === 'Dialog') {
        if (item.childType === EditItemNodeChildType.SelectOne) return false;
        if (item.childType === EditItemNodeChildType.SelectMany) return false;
      }
      return true;
    }
    if (source === 'Component') return node.editItem.children;
    const result: EditItem[] = EditItem.sort(node.children.filter((item) => acceptItem(item)).map(c=>c.editItem));
    return result;
  }

  const buildCombinedChoices = () : ReactNode => {
    if (!combine) return <></>;
    return (
      node.children.filter(c=>c.childType===EditItemNodeChildType.SelectMany).map((choice:EditItemNodeChild, i:number)=> {
      return (
      <div key={i}>
        <Typography variant='h2' fontSize={screenSize.getFontSize('h2')} style={{padding: marginH}}>{choice.editItem.name}</Typography>
        <ProductSelectManyChoices parent={choice.editItem}/> 
      </div>
    )}));
  }

  return (
    <div className={styles.productSelect}>
      <ProductSelectManyChoices parent={node.editItem} choices={resolveNodes()} />
      {buildCombinedChoices()}
    </div>
    );
};

export default ProductSelectMany;
