import { ErrorInfo, FC, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { KioskContext } from '../../../../context/KioskContext';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import React from 'react';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import ComponentDialog, { Action } from '../ComponentDialog';

interface Props {
  visible: boolean;
  onClose?: () => void;
}

const LanguageDialog: FC<Props> = ({visible, onClose}) => {

  const {state, dispatch} = useContext(KioskContext);
  //const { screenSize } = Config();
  const {t, i18n} = useTranslation();

  const closing = (code: string) => {
    if (code !== i18n.language){
      i18n.changeLanguage(code);
      dispatch({ type: KioskActionsEnum.RELOAD });
    }

    if (onClose)onClose();
  }

const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
  handleApplicationError(area, state.cart?.id, undefined, error);
}

const languageChoices = (): Action[] => {
  const defaultLanguages: string[] = ['fi', 'se', 'en'];
  const languagesInUse: string[] = process.env.REACT_APP_LANGUAGES ? process.env.REACT_APP_LANGUAGES.toLowerCase().split(';') : defaultLanguages;
  const result: Action[] = [];
  languagesInUse.forEach((l: string, i:number) => {
    if (defaultLanguages.find((d)=>d===l)!==undefined){
      result.push({ code: l, caption: t(`languages.${l}`), buttonType: i18n.language===l ? 'Primary' : 'Secondary' });
    }
  });
  return result;
};

return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('LanguageDialog', error, info)}>
    <ComponentDialog visible={visible} onClose={(code:string)=> {closing(code);}}
            actions={languageChoices()}
            title={t('changeLanguage')}
            fullscreen={false}>
    </ComponentDialog>
  </ErrorBoundary>
  );
};

export default LanguageDialog;
