import { ErrorInfo, FC, useContext } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import BasketProductList from '../../Controls/BasketProductList';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import Button from '../../Controls/Button';
import Config from '../../../../hooks/Config';
import Container from '../../Controls/Container';
import { formatMoney } from '../../../../helpers/formatHelper';
import SalePathData from '../../../../models/SalePathData';

interface Props {
}

const ConfirmOrder: FC<Props> = () => {

  const { screenSize } = Config();
  const {state, dispatch} = useContext(KioskContext);
  const {t} = useTranslation();

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const executePayment = () => {
    const isPaid:boolean = state.cart?.state.toLowerCase()==='finalized';
    dispatch({type: KioskActionsEnum.SET_STEP, step:isPaid ? StepsEnum.paid : StepsEnum.payment});
  }

  const isInvalid:boolean = SalePathData.getInvalidProducts(state.salePathData, state.cart).length > 0;

  const width: number = screenSize.getWidth(900);
  const leftMargin: number = screenSize.getWidth(20);
  const marginBottom: number = screenSize.getWidth(80);
  const marginOrder: number = screenSize.getWidth(30);
  
  return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('ConfirmOrder', error, info)}>
    <div className={styles.confirmOrder} style={{width: width, marginLeft: leftMargin, paddingBottom: marginBottom}}>
    <Container>
      <div className={styles.order} style={{margin: marginOrder}}>
        <BasketProductList />
      </div>
    </Container>
    <div className={styles.continue} style={{}}>
    <Button buttontype='Primary' onClick={executePayment} disabled={isInvalid}>
      {`${t('button.pay-the-order')} ${formatMoney(state.cart?.receiptTotal||0)}€`}
      </Button>
      </div>
    </div>
  </ErrorBoundary>
  );
};

export default ConfirmOrder;
