import { ErrorInfo, FC, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError } from '../../../../helpers/loggerApi';
import Button from '../../Controls/Button';
import Config from '../../../../hooks/Config';
import Container from '../../Controls/Container';
import { alpha, Typography, useTheme } from '@mui/material';
import ComponentDialog from '../../Dialogs/ComponentDialog';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import PaycoPayment from '../../../../hooks/PaycoPayment';

interface Props {
}

const Payment: FC<Props> = () => {

  const { screenSize } = Config();
  const {state, dispatch} = useContext(KioskContext);
  const theme = useTheme();
  const [executingPayment, setExecutingPayment] = useState<boolean>(false);
  const [cancellingPayment, setCancellingPayment] = useState<boolean>(false);
  const [paymentScreenMessage, setPaymentScreenMessage] = useState<string>('')
  const {paycoState, paycoExecute, paycoCancel, paycoReset} = PaycoPayment();
  //const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [errorDialogShown, showErrorDialog] = useState<boolean>(false);
  const {t} = useTranslation();

  const [returnTimer, setReturnTimer] = useState<NodeJS.Timeout|null>(null);
  const [forceDisabled, setForceDisabled] = useState<boolean>(true);

  useEffect(() => {
    const tick = () => {
      setForceDisabled(false);
      if (returnTimer) {
        clearTimeout(returnTimer);
        setReturnTimer(null);
      }
    }
    if (forceDisabled){
      setReturnTimer(setTimeout(() => tick(),1000));
    }
  },[forceDisabled, returnTimer]);


  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  useEffect(() => {

    const readyToStart = () : boolean => {
      if ((state.paymentPending||false)===true)return false;
      if (executingPayment===true)return false;
      if (state.cart==null)return false;
      if (state.cart.state.toLowerCase()!=='sale')return false;
      if (errorDialogShown===true)return false;
      return true;
    }
  
    if (readyToStart()){
      //console.log('Starting payment', '--------------------------------------------', t('payment.begin'));
      setPaymentScreenMessage(t('payment.begin'));
      dispatch({type: KioskActionsEnum.PAYMENT_INPROCESS})
      setExecutingPayment(true);
      setCancellingPayment(false);
      //setErrorOccured(false);
      paycoExecute();
      return;
    }
    
    if (paycoState.isCompleted){

      if (paycoState.isSucceeded){

          //Payment Completed
          if (state.cart?.state.toLowerCase()==='finalized'){
            //console.log('Completed payment');
            dispatch({type: KioskActionsEnum.PAYMENT_SUCCESSFUL})
            dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.paid});
            dispatch({type: KioskActionsEnum.RELOAD})
          }
          else {
            dispatch({type: KioskActionsEnum.PAYMENT_FAILED})
            setPaymentScreenMessage(t('payment.exception-abort'));
            //setErrorOccured(true);
            showErrorDialog(true);
          }
        } else {

          // Error in Payment
          dispatch({type: KioskActionsEnum.PAYMENT_FAILED})
          //setErrorOccured(true);
          if (paycoState.exception)
            setPaymentScreenMessage(t('payment.exception-abort'));
          else
            setPaymentScreenMessage(t('payment.error'));
          showErrorDialog(true);
        }
        setExecutingPayment(false);
        paycoReset();
    }
  }, [dispatch, paycoExecute, paycoReset, paycoState.exception, paycoState.isCompleted, paycoState.isSucceeded, state.cart?.state, state.paymentPending, t, executingPayment, state.cart, errorDialogShown]);

  useEffect(() => {
    //if (paycoState.message !== '') console.log('paycoMessage', paycoState.message);
    if (paycoState.message!=='' && paymentScreenMessage!== paycoState.message) setPaymentScreenMessage(paycoState.message);
  }, [paycoState, paymentScreenMessage]);  

  const cancelPayment = () => {
    setCancellingPayment(true);
    paycoCancel();
  }

  const executeRetry = () => {
    setPaymentScreenMessage('');
    setExecutingPayment(false);
    setCancellingPayment(false);
    //setErrorOccured(false);
    showErrorDialog(false);
  }

  const executeReturn = () => {
    dispatch({type: KioskActionsEnum.SET_STEP, step:StepsEnum.collect});
    //setErrorOccured(false);
    showErrorDialog(false);
  }

  const width: number = screenSize.getWidth(550);
  const marginRight: number = screenSize.getWidth(50);
  const marginBottom: number = screenSize.getWidth(50);
  const marginPopup: number = screenSize.getWidth(20);
  const triangleWidth: number = marginBottom * 1.5;
  const triangleHeight: number = marginBottom;
  const triangleShadow: number = screenSize.getWidth(2);

  const dialogMarginSize: number = screenSize.getWidth(40);
  const errorIconSize = screenSize.getWidth(100);
  const sizeH2 = screenSize.getFontSize('h2');
  const sizeBody = screenSize.getFontSize('body1');

  const retryOnError = (retry: boolean) => {
    if (retry)executeRetry();
    else executeReturn();
  }

  <button className={`button ${(!executingPayment || cancellingPayment) ? styles.disabledBtn : ''}`} onClick={cancelPayment} disabled={!executingPayment}>{t('button.cancel-payment')}</button>

  const cancelDisabled = forceDisabled || (!executingPayment || cancellingPayment);

  return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Payment', error, info)}>
    <div className={styles.payment}>
      <div className={styles.popup} style={{width: width, right: marginRight, marginBottom: marginBottom}}>
        <Container>
          <div className={styles.popupContent} style={{padding: marginPopup}}>
            <Typography variant='h2' style={{fontSize: sizeH2, textAlign: 'start', marginTop: marginPopup, marginBottom: dialogMarginSize }}>{t('button.pay-the-order')}</Typography>
            <Typography variant='body1' style={{fontSize: sizeBody, fontWeight:600, marginTop: dialogMarginSize, marginBottom: dialogMarginSize}}>{paymentScreenMessage}</Typography>
            <Typography variant='body1' style={{fontSize: sizeBody, fontWeight:600, marginTop: dialogMarginSize, marginBottom: dialogMarginSize}}>{t('pay-info')}</Typography>
            <div className={styles.cancel}>
              <Button buttontype='Secondary' onClick={cancelPayment} disabled={cancelDisabled}>{t('button.cancel-payment')}</Button>
            </div>
          </div>
          <div className={styles.triangle} style={{
            right: marginBottom - triangleShadow,
            borderTopWidth: triangleHeight - triangleShadow,
            borderLeftWidth: triangleWidth/2,
            borderRightWidth: triangleWidth/2,
            borderTopColor: alpha(theme.palette.text.primary, 0.2)}}>
          </div>
          <div className={styles.triangle} style={{
            right: marginBottom,
            borderTopWidth: triangleHeight,
            borderLeftWidth: triangleWidth/2,
            borderRightWidth: triangleWidth/2,
            borderTopColor: theme.palette.background.default}}>
          </div>
        </Container>
      </div>
      <ComponentDialog visible={errorDialogShown} onClose={(code: string) => retryOnError(code === 'Retry')}
      actions={[
        { code: 'Return', caption: t('button.return'), buttonType: 'Secondary'},
        { code: 'Retry', caption: t('button.retry'), buttonType: 'Primary'}
      ]}
      fullscreen={false}>
        <>
          <ErrorIcon style={{fontSize:errorIconSize, width: '100%'}}/>
          <Typography variant='h2' style={{fontSize: sizeH2, marginTop: dialogMarginSize, marginBottom: dialogMarginSize }}>{t('pay-error')}</Typography>
          <Typography variant='body1' style={{fontSize: sizeBody, marginTop: dialogMarginSize, marginBottom: dialogMarginSize }}>{t('pay-error-info')}</Typography>
        </>
      </ComponentDialog>
    </div>
  </ErrorBoundary>
  );
};

export default Payment;
