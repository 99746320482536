import { FC, useContext } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import SalePath from '../../../../models/SalePath';
import SalePathItem from '../SalePathItem';
import Config from '../../../../hooks/Config';

interface Props {
  parent?: SalePath;
  columnCount?: number;
  selectEnabled?: boolean;
  selectSingle?: boolean;
  choices?: SalePath[];
  selected?: SalePath[];
  onClick?: (node: SalePath) => void;
}

const SalePathList: FC<Props> = ({parent, columnCount=4, selectEnabled = false, choices, selected = [], onClick}) => {
  const {state} = useContext(KioskContext);
  const { screenSize } = Config();

  const conceptClick = (salePath:SalePath) => {
    if (onClick){
      onClick(salePath);
    }
  }

  const nodeWidth: number = screenSize.getWidth(1080) / (columnCount+(parent ? 1: 2));
  const nodeHeight: number = nodeWidth * 1.3;
  const nodeMargin: number = nodeWidth * 0.02;

  const getActiveItems = () : SalePath[] => {
    if (choices && choices.length>0){
      return SalePath.sort(choices);
    }
    const items = parent ? parent.children : state.salePathData.rootItems.filter(c => c.isVisible);
    const concepts:SalePath[] = SalePath.sort(items).filter(c => 
      c.id > 0
    );
    return concepts;
  }

  const buildTableRows = (nodes: SalePath[]) : any => {
    const rows: number = Math.ceil(nodes.length / columnCount);
    const result: SalePath[][] = [];
    for (let row = 0; row < rows; row++) {
      const items: SalePath[] = [];
      for(let col = 0; col < columnCount; col++) {
        const index: number = (row*columnCount) + col;
        if (index<nodes.length){
          items.push(nodes[index]);
        }
      }
      result.push(items);
    }
    return result.map((r: SalePath[], i1: number) => (
      <tr  key={i1}>
        {r.map((node: SalePath, i2: number) => {
          const isSelected: boolean = selectEnabled ? (selected.length===0 && node.code === '') || (selected.find(s=>node.code===s.code)!==undefined) : false;
          return (
          <td style={{placeItems: 'center'}} key={i2}>
            <div className={styles.node} style={{width: nodeWidth, height: nodeHeight, margin: nodeMargin}}>
              <SalePathItem node={node} isSelected={isSelected} onClick={()=>conceptClick(node)} />
            </div>
          </td>
        )})}
      </tr>
    )
    );
  }

  return (
    <div className={styles.itemsContainer}>

      <div className={styles.items}>
        <table className={styles.itemTable}>
          <tbody>
            {buildTableRows(getActiveItems())}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SalePathList;
