import config_Common from './config_common.json';
import config_Helge from './config_Helge.json';
import config_Neste from './config_Neste.json';
import config_CGI from './config_CGI.json';

const resolveEntry = (path: string, obj: any) : string|null => {
  return path.split('.').reduce(function(prev: any, curr: any) {
      return prev ? prev[curr] : null
  }, obj || this)
}

export const getCompanyConfigFlag = (key: string, defaultValue: boolean = false) : boolean => {
  const asString = getCompanyConfig(key, defaultValue ? 'true' : 'false').toLowerCase();
  return asString === 'true' || asString === '1';
}
export const getCompanyConfig = (key: string, defaultValue: string = '') : string => {

  const common:any = config_Common;
  let custom:any = null;
  const configName = process.env.REACT_APP_COMPANY_NAME;

  switch(configName?.toLowerCase()){
    case 'cgi': {
      custom = config_CGI;
      break;
    }
    case 'helge': {
      custom = config_Helge;
      break;
    }
    case 'neste': {
      custom = config_Neste;
      break;
    }
  }

  let result: string|null|undefined = null;
  if (custom){
    result = resolveEntry(key, custom);
    //console.log('resolveEntry', key, configName, result, defaultValue);
  }
  if (result == null || result === undefined){
    result = resolveEntry(key, common);
    //console.log('resolveEntry2', key, 'common', result, defaultValue);
  }
  //console.log('resolveEntry', 'result', key, `>${result}<`);
  return (result==null||result===undefined) ? defaultValue : result;
}
