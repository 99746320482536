import { useState, useContext } from 'react';
import {
  getCart,
  getReceiptLayout,
  getServiceId,
} from "../helpers/basketApi";
import {
  PaymentStatus,
  startCartPayment,
  cancelCartPayment,
  getCartPaymentStatus,
} from "../helpers/paycoApi";
import { KioskContext } from '../context/KioskContext';
import { KioskActionsEnum } from '../context/KioskContext/types';
import { postReceiptLayout } from '../helpers/upproxyApi';
import { handleApiError, handleApplicationError } from '../helpers/loggerApi';

export interface IPaymentyState {
  amount: number;
  isProcessing: boolean;
  message: string;
  isCompleted: boolean;
  isSucceeded: boolean;
  exception: any;
}

const initialPaymentState: IPaymentyState = {
  amount: 0,
  isProcessing: false,
  message: '',
  isCompleted: false,
  isSucceeded: false,
  exception: null
};

export default function PaycoPayment() {

  const [paymentState, setPaymentState] = useState<IPaymentyState>(initialPaymentState);
  const {state, dispatch} = useContext(KioskContext);

  const cancel = async () => {
    if (state.cart==null)return;
    try {
      cancelCartPayment(state.cart.id);
    } catch (error) { }
  }

  const reset = async () => {
    if (paymentState.isProcessing){
      cancel();
    } else {
      setPaymentState(initialPaymentState);
    }
  }

  const execute = async () => {
    if (paymentState.isProcessing)return;
    setPaymentState(initialPaymentState);
    if (state.cart==null)return;
    if (state.cart.state.toLowerCase()==='finalized')return;
    if (state.cart.receiptTotal<=0)return;

    const paymentAmount:number = state.cart.receiptTotal;

    setPaymentState({
      ...paymentState,
      amount: paymentAmount,
      isProcessing: true,
    });

    try {
      if (!state.cart.serviceID){
        var cart = await getServiceId(state.cart.id);
        dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      }

      var ok = await startCartPayment(state.cart.id);
      if (ok){
        finalizePayment(state.cart.id);
      } else {
        setPaymentState({
          ...paymentState,
          amount: paymentAmount,
          isSucceeded: false,
          isCompleted: true,
          isProcessing: false,
          exception: null,
        });
      }
    } catch (error) {
      handleApplicationError('Payment', state.cart.id, 'Failed to process payment.', error);
      setPaymentState({
        ...paymentState,
        amount: paymentAmount,
        isSucceeded: false,
        isCompleted: true,
        isProcessing: false,
        exception: error,
      });
    }
  }

  function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
  }

  const printReceipt = async (cartId:string) => {
    const layout:string = await getReceiptLayout(cartId);
    var result:boolean|unknown = false;
    try {
      result = await postReceiptLayout(layout);
    } catch (error) {
      result = error as Error;
    }
    if (result instanceof Error) {
      handleApiError('postReceiptLayout', result, cartId, {layout:layout});
    } else if (!(result instanceof Boolean)) {
      handleApiError('postReceiptLayout', result, cartId, {layout:layout});
    }
  }

  const paymentCompleted = (cartId:string) => {
    printReceipt(cartId);
  }

  const finalizePayment = async (cartdId:string) => {
    let counter : number = 0;
    const maxCount : number = 60;
    var status:PaymentStatus|null = null;
    do {
      await timeout(1000);
      counter = counter + 1;
      try {
        status = await getPaycoStatus();
      } catch (error) {
        setPaymentState({
          ...paymentState,
          isSucceeded: false,
          isProcessing: true,
          isCompleted: false,
          exception: error,
        });
        continue;
      }
      if (status && paymentState.message !== status.screenMessage){
        setPaymentState({
          ...paymentState,
          message: status.screenMessage,
        });
      }
    } while ((status==null || (status.isDone===false && status.isError===false)) && counter < maxCount);
    if (counter >= maxCount){
      setPaymentState({
        ...paymentState,
        isSucceeded: false,
        isCompleted: true,
        isProcessing: false,
        });
    } else if (status == null){
      setPaymentState({
        ...paymentState,
        isSucceeded: false,
        isCompleted: true,
        isProcessing: false,
        });
    } else if (status?.isError){
      setPaymentState({
        ...paymentState,
        isSucceeded: false,
        isCompleted: true,
        isProcessing: false,
        });
    } else if (status?.isDone !== null){
      setPaymentState({
        ...paymentState,
        isSucceeded: true,
        isCompleted: true,
        isProcessing: false,
        });
      paymentCompleted(cartdId);
    } 
  }

  const getPaycoStatus = async () : Promise<PaymentStatus> => {
    const status = await getCartPaymentStatus(state.cart?.id||'');
    //console.log('getPaycoStatus', state.cart?.id||'', status);
    if (status.isDone && state.cart){
      const cart = await getCart(state.cart.id);
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
    return status;
  }

  return  { paycoState: paymentState, paycoExecute: execute, paycoCancel: cancel, paycoReset: reset };
}