import { useState } from 'react';
import { getCompanyConfig } from '../companyConfig/companyConfig';

export type FontSize = 'h1'|'h2'|'subtitle1'|'subtitle2'|'body1'|'body2'|'menu'|'button'|'buttonSmall'|'buttonLarge'|'Default';

class ScreenSize {
  constructor(maxHeight: number, targetWidth: number, targetHeight: number, scaleWidth: number) {
    this.maxHeight = maxHeight;
    this.targetWidth = targetWidth;
    this.targetHeight = targetHeight;
    this.scaleWidth = scaleWidth;
  }
  public maxWidth: number = 1080;
  public maxHeight: number = 1920;
  public targetWidth: number;
  public targetHeight: number;
  public scaleWidth: number = 1;
  public getWidth = (value?: number) : number => {return value ? value > this.maxWidth ? this.maxWidth : value * this.scaleWidth : this.maxWidth * this.scaleWidth;}
  public getHeight = (value?: number) : number => {return value ? value > this.maxHeight ? this.maxHeight : value * this.scaleWidth : this.maxHeight * this.scaleWidth;}
  public getFontSize = (target: FontSize = 'Default', defaultValue: number = 50) : number => 
    {
      const root = 'UI.FontSize.';
      let value = getCompanyConfig(`${root}${target}`, '');
      if (value==='') value = getCompanyConfig(`${root}Default`, '');
      if (value==='') value = defaultValue.toString();
      return this.getWidth(Number.parseFloat(value));
    }
}

export default function Config () {

  const debugKey:string = 'debug-mode';

  const [version] = useState<string>(`${(process.env.REACT_APP_BRANCH && process.env.REACT_APP_BRANCH !== 'master' && process.env.REACT_APP_BRANCH !== 'local') ? `(${process.env.REACT_APP_BRANCH}) `:''}${process.env.REACT_APP_COMMIT||''}`);

  const debugMode:boolean = document.documentElement.getAttribute(debugKey)!=null;

  const screenSizeRuntime = () : ScreenSize => {
    const result: ScreenSize = new ScreenSize(
      Number.parseInt(document.documentElement.getAttribute('maxheight')||'1920'),
      Number.parseInt(document.documentElement.getAttribute('targetwidth')||'1080'),
      Number.parseInt(document.documentElement.getAttribute('targetheight')||'1920'),
      Number.parseFloat(document.documentElement.getAttribute('scalewidth')||'1')
    );
    return result;
  }

  const screenSize: ScreenSize = screenSizeRuntime();

  const toggleDebugMode = () => {
    const currentValue:boolean = document.documentElement.getAttribute(debugKey)!=null;
    if (currentValue){
      document.documentElement.removeAttribute(debugKey);
      console.log('DEBUG OFF');
    } else {
      document.documentElement.setAttribute(debugKey, 'enabled')
      console.log('DEBUG ON');
    }
  }
  
  return  { version: version, debugMode: debugMode, toggleDebugMode: toggleDebugMode, screenSize, screenSizeRuntime };
}
